import React, { useEffect, useState } from 'react'
import ProfileService from '../../services/Profile';
import EditProfile from '../../components/EditProfile/EditProfile';
import { IProfilData } from '../../interface/TransferMoney';
import { ISnackbar } from '../../interface/Snackbar';
import { LocalStorage } from '../../enums/LocalStorage';
import SnackbarAlert from '../../components/SnackbarAlert/SnackbarAlert';
import { Modal } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { URLRoutes } from '../../enums/Routes';
import moment from 'moment';

const EmptyPage = () => {
    const [profileData, setProfileData] = useState<any>();
    const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false, severity: 'success', message: '' });
    const [openModal, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [hideCloseIcon,] = useState<boolean>(true);
    const history = useHistory();

    useEffect(() => {
        getProfileData();
        handleOpen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProfileData = () => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const userId = JSON.parse(localStorageDataAuth ? localStorageDataAuth : '').id;
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null

        ProfileService.getUserInformation(userId, token).then(response => {
            const { data } = response;
            console.log('data,', data);
            if (data[0] !== undefined) {
                setProfileData(data[0]);
            }

        })
    }

    const saveNewProfilData = (parameters: IProfilData | undefined) => {

        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const userId = JSON.parse(localStorageDataAuth ? localStorageDataAuth : '').id;
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null
        // if (parameters?.DATUM_ISTEKA_ID) {
        //     parameters.DATUM_ISTEKA_ID = moment(parameters.DATUM_ISTEKA_ID, 'DD/MM/YYYY').format('YYYY-MM-DD');
        // }

        const newUserData = new FormData()
        newUserData.append('IMG_ID', parameters?.FILE_UPLOAD);

        if (parameters?.FILE_UPLOAD) {
            ProfileService.updatePicture(newUserData, token).then(response => {
                getProfileData();
                setOpen(false);
            }).catch(error => {
                // console.log(error)
            });
        }

        ProfileService.updateUserInformation(userId, parameters, token).then(response => {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'Profile updated.'
            })
            getProfileData();
            setOpen(false);
            const localStorageDataAuthJSON: any = JSON.parse(localStorageDataAuth ? localStorageDataAuth : '');
            localStorageDataAuthJSON.validateProfile = true;

            localStorage.setItem('auth', JSON.stringify(localStorageDataAuthJSON));
            history.push(URLRoutes.MoneyTransfer);
            snackbarAutoHide();
        }).catch(error => {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        });
    }

    const snackbarAutoHide = () => {
        setTimeout(() => {
            setSnackbar({ open: false });
        }, 5000);
    }

    return (<div>
        {profileData !== undefined ?
            <Modal
                open={openModal}
                aria-labelledby="modal-profile-title"
                aria-describedby="modal-profile-description">
                <div><EditProfile close={() => handleClose()} profileData={profileData} saveNewProfilData={saveNewProfilData} hideCloseIcon={hideCloseIcon} firstLogin={true} /></div>
            </Modal>
            : null

        }

        <SnackbarAlert open={snackbar.open} severity={snackbar.severity} message={snackbar.message} />
    </div>)
}

export default EmptyPage;