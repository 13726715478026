import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './ResetPassModal.module.scss'
import { Box, TextField, IconButton, InputAdornment } from '@material-ui/core';
import { INewProfilData } from '../../interface/TransferMoney';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import DatePicker from 'react-date-picker';

export interface IResetPassModal {
    close?: (event: MouseEvent) => void,
    saveNewPassValue?: (parameters: { LOZINKA: string }) => void,
}

const ResetPassModal = (props: IResetPassModal) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        p: 4,
    };


    const [passwordValue, setPasswordValue] = useState<{ LOZINKA: string }>({ LOZINKA: '' });
    const [saveButton, setSaveButton] = useState<boolean>(false);

    const [errorInputConfirmPass, setErrorInputConfirmPass] = useState(false);
    const [errorInputPass, setErrorInputPass] = useState(false);


    const [errorMsgConfirmPass, setErrorMsgConfirmPass] = useState<string | undefined>('');

    const [confirmPass, setConfirmPass] = useState<string | undefined>('');

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [errorMsgPassword, setErrorMsgPassword] = useState<string | undefined>('');
    const [errorInputPassword, setErrorInputPassword] = useState(false);


    const handleClick = () => {
        setShowPassword(prev => !prev);
    }

    const handleClickConfirmPass = () => {
        setShowConfirmPassword(prev => !prev);
    }


    const handleChange = (event: ChangeEvent<{ name?: string; value: string }>) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name as keyof typeof passwordValue;

        switch (name) {
            case 'LOZINKA': {

                if (value.length > 0) {
                    setErrorInputPass(true);
                    setErrorMsgPassword(helperValidatePassword(value))
                    setErrorInputPassword(helperValidatePassword(value) ? true : false);
                    setSaveButton(false);
                } else if (value === '' || value.length === 0) {
                    setErrorInputPass(false);
                    setErrorMsgConfirmPass('');
                    setSaveButton(true);
                } else

                    break;
            }
        }

        setPasswordValue(prevState => ({
            ...prevState,
            [name]: value
        }));
        setSaveButton(true);
    }

    const handleChangeConfirmPass = (event: ChangeEvent<{ name?: string; value: string }>) => {
        const target = event.target;
        let value = target.value;

        setConfirmPass(value);
        if (passwordValue.LOZINKA !== value) {
            setErrorInputConfirmPass(true);
            setErrorMsgConfirmPass('Passwords do not match!');
            setSaveButton(false);
        } else {
            setErrorInputConfirmPass(false);
            setErrorMsgConfirmPass('');
            setSaveButton(true);
        }

    }

    useEffect(() => {
        if (!errorMsgConfirmPass && passwordValue.LOZINKA?.length >= 8 && confirmPass === passwordValue.LOZINKA) {
            setSaveButton(true);
        } else {
            setSaveButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMsgConfirmPass, passwordValue, confirmPass]);



    const helperValidatePassword = (password: string) => {
        let regPass = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        if (password.length < 8 || !regPass.test(password)) {
            return 'The password should have at least 8 Characters, combination of uppercase, lower cases, special character and numbers!'
        }
    }

    const saveProfil = () => {
        if (props.saveNewPassValue) {

            props.saveNewPassValue(passwordValue);
            setSaveButton(false);
        }
    }


    return <Box sx={style}>

        <div className={styles.reset_pass_container}>
            <div className={styles.title_container}>
                <div>
                    <div className={styles.title}>Change password.</div>
                    <div className={styles.subtitle}>The password should have at least 8 Characters, combination of uppercase, lower cases, special character and numbers!</div>
                    {/* <div className={styles.subtitle}>In order to protect your account, make sure your password:</div>
                    <div className={styles.subtitle}> - Is longer that 8 characters</div>
                    <div className={styles.subtitle}> - Has one uppercase character</div>
                    <div className={styles.subtitle}> - Has one lowercase character</div> */}
                </div>
            </div>
            <div className={styles.autocomplete_container}>
                <div className={styles.center_position}>
                    <div className={styles.input_label}>New Password</div>
                    {/* <TextField autoComplete='off' name='LOZINKA' className={styles.input_field} placeholder={'New Password'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 120 }} /> */}

                    <TextField autoComplete='off' name='LOZINKA' className={styles.input_field} variant="outlined" placeholder={'New Password'} type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClick}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        helperText={errorMsgPassword} error={errorInputPassword} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 120 }} />
                </div>
                <div className={styles.center_position}>
                    <div className={styles.input_label}>Confirm Password</div>

                    <TextField autoComplete='off' name='POTVRDA_LOZINKA' value={confirmPass} helperText={errorMsgConfirmPass} error={errorInputConfirmPass} className={styles.input_field} variant="outlined" placeholder={'Confirm Password'} type={showConfirmPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickConfirmPass}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeConfirmPass(e)} inputProps={{ maxLength: 120 }} />
                </div>

            </div>

            <div className={styles.buttons_container}>
                <button className={`${styles.save_button} ${!saveButton ? styles.disabled : ''}`} onClick={saveProfil}>SAVE</button>
            </div>
        </div>


    </Box>

}

export default ResetPassModal;