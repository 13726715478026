import {
    atom
} from 'recoil';

export const SelectedRecipientID = atom({
    key: 'SelectedRecipientID',
    default: '',
});

export const payoutMethodAccountEur = atom<boolean | null>({
    key: 'payoutMethodAccountEur',
    default: null,
});

export const balkanCountryAtom = atom<{} | null>({
    key: 'balkanCountryAtom',
    default: null,
});