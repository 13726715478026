import { DialogContent, DialogContentText, DialogActions, Button, Dialog } from "@material-ui/core";
import React from 'react';

interface IDialog {
    openDialog: boolean,
    confirm: () => void,
    message: string
}


const DialogPopup = (props: IDialog) => {
    const { openDialog, confirm, message } = props;

    return <div className="dialog_wrapper">
        <Dialog
            open={openDialog}
            keepMounted
        >
            <DialogContent>
                <DialogContentText style={{
                    fontWeight: '500',
                    fontSize: "16px",
                    color: "#04539B",
                    textAlign: "center"
                }}>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={{
                    color: "#04539B"
                }} onClick={confirm}>OK</Button>
            </DialogActions>
        </Dialog>
    </div>
}

export default DialogPopup;