import axios from './config/axios';

interface IRegisterData {
    email: string,
    password: string,
    first_name: string,
    last_name: string,
    terms: boolean
}

class ProfileService {

    getUserInformation = (id: string, token: string) => {
        return axios({
            method: 'post',
            url: `/account/data/user/${id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    updateUserInformation = (userID: string, params: any, token: string) => {
        // delete params.LOZINKA;
        delete params.TEMP_LOZINKA;
        // delete params.ZANIMANJE;
        // delete params.KORAK_REG_1;
        // delete params.KORAK_REG_2;
        // delete params.DONE;
        const newObj = Object.fromEntries(
            Object.entries(params).map(([k, v]) => [k.toLowerCase(), v])
        );

        return axios({
            method: 'post',
            url: `/account/data/user/edit/${userID}`,
            data: newObj,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }


    updatePicture = (params: FormData, token: string) => {
        return axios({
            method: 'post',
            url: `/upload/upload-file`,
            data: params,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    regiserNewUser = async (registerData: IRegisterData) => {
        return axios.post('/signup/registration', registerData).then(data => {
            return data;
        });
    };


    updateForgotPass = async (LOZINKA: string, USERID: string, token: string) => {
        const data = {
            LOZINKA,
            USERID
        }

        return axios({
            method: 'post',
            url: `/account/data/user/change/password`,
            data: data,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

    };

}
export default new ProfileService();