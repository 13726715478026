import { Route, Redirect, useLocation, useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { URLRoutes } from "../../enums/Routes";

export const SecureRoute = ({ role, ...rest }: any) => {
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        let adminLevelString = localStorage.getItem('auth');
        let currentRole = adminLevelString && adminLevelString.length > 0 ? JSON.parse(adminLevelString) : '';
        const user = [
            URLRoutes.SetProfileData, URLRoutes.Profile, URLRoutes.AboutUs, URLRoutes.TransactionItem,
            URLRoutes.TransactionListing, URLRoutes.MoneyTransfer
        ]
        const admin = [
            URLRoutes.TransactionView, URLRoutes.UserItem, URLRoutes.Transactions, URLRoutes.ExchangeRate,
            URLRoutes.CommissionFee, URLRoutes.PurposeOfTransfer, URLRoutes.PayoutOptions,
            URLRoutes.PaymentOptions, URLRoutes.Users, URLRoutes.Admins
        ]
        if (adminLevelString) {
            if (!currentRole.validateProfile && location.pathname !== URLRoutes.SetProfileData) {
                window.location.href = URLRoutes.SetProfileData;
                history.push({
                    pathname: `${URLRoutes.SetProfileData}`
                });
            }

            if ((currentRole.forgotPass === 'TRUE' || currentRole.forgotPass === 'true' || currentRole.forgotPass === true) && location.pathname !== URLRoutes.ConfirmNewPass) {
                window.location.href = URLRoutes.ConfirmNewPass;
                history.push({
                    pathname: `${URLRoutes.ConfirmNewPass}`
                });
            }

            if (currentRole.ov === 3) {
                for (let index = 0; index < user.length; index++) {
                    if (user[index] === location.pathname && currentRole.ov === 3) {
                        window.location.href = URLRoutes.Transactions;
                    }
                }
            } else {
                for (let index = 0; index < admin.length; index++) {
                    if (admin[index] === location.pathname && currentRole.ov === 1) {
                        window.location.href = URLRoutes.MoneyTransfer;
                    }
                }
            }
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    let adminLevelString = localStorage.getItem('auth');
    let currentRole = adminLevelString && adminLevelString.length > 0 ? JSON.parse(adminLevelString) : '';

    if (adminLevelString) {
        if (currentRole.ov === Number(role)) {
            return <Route exact={rest.exact} path={rest.path}>{rest.children}</Route>;
        } else {
            return null;
        }
    } else {
        return <Redirect to={URLRoutes.Login} />
    }
};

export default SecureRoute;
