import { Button, Popover } from '@material-ui/core';
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import TransactionListingFilter from '../../components/Filters/TransactionListingFilter/TransactionListingFilter';
import UserTransactionTable from '../../components/Tables/UserTransactionTable/UserTransactionTable';
import styles from './TransactionListing.module.scss';

const TransactionListing = () => {
    const location = useLocation();
    const queryString = require('query-string');
    const tableUrlParams = ['page', 'order_by'];
    const [filterDialog, setFilterDialog] = useState<HTMLButtonElement>();
    const [filterParams, setFilterParams] = useState<any>(queryString.parse(location.search) ? Object.fromEntries(Object.entries(queryString.parse(location.search)).filter(([k, _]) => !tableUrlParams.includes(k))) : {});
    const isEmptyObject = (object = {}) => {
        return Object.keys(object).length === 0;
    }

    const applyFilters = (parameters: any | undefined) => {
        setFilterParams(parameters);
        setFilterDialog(undefined);
    }

    return <div className={styles.transaction_listing_wrapper}>
        <div className={styles.menu_wrapper}></div>
        <div className={styles.header_section}>
            <div className={styles.title}>Transaction listing</div>
            <div className={styles.subtitle}>Below you can see a list of your transactions.</div>
        </div>
        <div className={styles.transaction_listing_filter}>
            <Button className={`${styles.filter_circle} ${!isEmptyObject(filterParams) ? styles.active_filter : ''}`} onClick={(e) => setFilterDialog(e.currentTarget)}><span className="icon icon_filter"></span></Button>
            <Popover id='transaction_listing_filter' open={Boolean(filterDialog)} anchorEl={filterDialog} onClose={() => setFilterDialog(undefined)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <TransactionListingFilter appliedFilters={filterParams} applyFilters={applyFilters} close={() => setFilterDialog(undefined)} datasetFilter={true} />
            </Popover>
        </div>
        <UserTransactionTable filterParameters={filterParams} />
    </div>
}

export default TransactionListing;