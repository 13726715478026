export enum URL {

    Login = 'https://apibeoexport.com.au.beoexport.com.au/auth/login',
    Registration = 'https://apibeoexport.com.au.beoexport.com.au/signup/registration',
    AddRecipient = 'https://apibeoexport.com.au.beoexport.com.au/recipeint/new',
    BaseURL = 'https://apibeoexport.com.au.beoexport.com.au',
    ForgetPassword = 'https://apibeoexport.com.au.beoexport.com.au/forgot/password',
    EditRecipient = 'https://apibeoexport.com.au.beoexport.com.au/recipeint/data/recipient/edit'

    // Login = 'http://newwebapp.hopto.org/auth/login',
    // Registration = 'http://newwebapp.hopto.org/signup/registration',
    // AddRecipient = 'http://newwebapp.hopto.org/recipeint/new',
    // BaseURL = 'http://newwebapp.hopto.org',
    // ForgetPassword = 'http://newwebapp.hopto.org/forgot/password',
    // EditRecipient = 'http://newwebapp.hopto.org/recipeint/data/recipient/edit'

    // Login = 'http://localhost:3005/auth/login',
    // Registration = 'http://localhost:3005/signup/registration',
    // AddRecipient = 'http://localhost:3005/recipeint/new',
    // BaseURL = 'http://localhost:3005',
    // ForgetPassword = 'http://localhost:3005/forgot/password',
    // EditRecipient = 'http://localhost:3005/recipeint/data/recipient/edit'
}