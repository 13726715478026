import React from 'react'
import styles from './AboutUs.module.scss';


const AboutUs = () => {

    return <div className={styles.about_us_wrapper}>
        <div className={styles.menu_wrapper}></div>
        <div className={styles.header_section}>
            <div className={styles.title}>Contact us</div>
            <div className={styles.subtitle}>If you have any questions queries a member of our staff will always be happy to help. Feel free to contact us.</div>
        </div>
        <div className={styles.infomation_section}>
            <div className={styles.img_section}>
                <span className="icon icon_beoexport_larger"></span>
            </div>
            <div className={styles.border_line}></div>
            <div className={styles.text_section}>
                <div className={styles.text_row}>
                    <div className={styles.title}>Company</div>
                    <div className={styles.subtitle}>Beo – Export Australia Pty Ltd</div>
                </div>
                <div className={styles.text_row}>
                    <div className={styles.title}>Address</div>
                    <div className={styles.subtitle}>Shop 2, Liverpool Plaza 165-191 Macquarie St, Liverpool NSW 2170</div>
                </div>
                <div className={styles.text_row}>
                    <div className={styles.title}>Mail</div>
                    <div className={styles.subtitle}>info@beoexport.com.au</div>
                </div>
                <div className={styles.text_row}>
                    <div className={styles.title}>Telephone number</div>
                    <div className={styles.subtitle}>02 8781 1950</div>
                </div>
                <div className={styles.text_row}>
                    <div className={styles.title}>Support Number</div>
                    <div className={styles.subtitle}>0411119455</div>
                </div>
            </div>
        </div>
    </div>
}

export default AboutUs;