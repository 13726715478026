import { Box, Fab, makeStyles, MenuItem, Modal, TextField, Tooltip, Typography } from '@material-ui/core';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import styles from './MoneyTransfer.module.scss';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import AddRecipient from '../../components/AddRecipient/AddRecipient';
import { useHistory } from 'react-router-dom';
import { URLRoutes } from '../../enums/Routes';
import { StepContext } from '../../components/StepContext/StepContext';
import { LocalStorage } from '../../enums/LocalStorage';
import UserTransactions from '../../services/UserTransactions';
import HelpIcon from '@material-ui/icons/Help';
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { formatDate } from '../../helpers/DateTimeHelper';
import { IEditRecipientlData, IRecipientData, ISendTransaction } from '../../interface/TransferMoney';
import SnackbarAlert from '../../components/SnackbarAlert/SnackbarAlert';
import { ISnackbar } from '../../interface/Snackbar';
import ProfileService from '../../services/Profile';
import { URL } from "../../enums/Url";
import EditRecipient from '../../components/EditRecipient/EditRecipient';
import { useRecoilState } from 'recoil';
import { payoutMethodAccountEur, SelectedRecipientID, balkanCountryAtom } from './transferMoneyStates';
import moment from 'moment';
import DialogPopup from '../../components/Dialog/Dialog';
import PopupFirstStep from '../../components/Dialog/DialogFirstStep';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                {/* <Typography className={styles.label_progress_bar}>{`${Math.round(props.value,)}%`}</Typography> */}
            </Box>
        </Box>
    );
}

const MoneyTransfer = (props: any) => {
    const [desktopWidth, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [burgerMenu, setBurgerMenu] = useState(false);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    const useStyles = makeStyles(theme => ({
        customTooltip: {
            padding: '10px',
            borderRadius: '10px',
            backgroundColor: '#04539B',
            color: '#FFFFFF',
            fontSize: "14px"
        }
    }));
    const classes = useStyles();
    const history = useHistory();
    const [progress, setProgress] = useState(0);
    const [numStepLabel, setNumStepLabel] = useState('1/4');
    const [step, setStep] = useState(1);
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setNewRecipientData(null);
        setOpen(true)
    };
    const handleClose = () => setOpen(false);
    const [openEditRecipient, setOpenEditRecipient] = useState(false);
    const handleOpenEditRecipient = () => {
        setNewRecipientData(null);
        setOpenEditRecipient(true)
    };
    const handleCloseEditRecipient = () => setOpenEditRecipient(false);

    const { setCurrentStep } = useContext(StepContext);
    const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false, severity: 'success', message: '' });
    // First step
    const [payingAmount, setPayingAmount] = useState<any>();
    const [payoutAmount, setPayoutAmount] = useState<any>(0.00);
    const [saveButtonFirstStep, setSaveButtonFirstStep] = useState<boolean>(false);

    // Calculator related
    const [exchangeRate, setExchangeRate] = useState<any>(0);
    const [payingAmountSideLabel, setPayingAmountSideLabel] = useState<any>(0.00);
    const [fee, setFee] = useState<any>(0);
    const [feeArray, setFeeArray] = useState<any>([]);
    const [discount, setDiscount] = useState<any>(0);
    const [feeCode, setFeeCode] = useState<any>(0);
    const [otherCosts, setOtherCosts] = useState<any>(0.00);
    const [totalPayable, setTotalPayable] = useState<any>(0);

    // Second step
    const [paymentMethod, setPaymentMethod] = useState<any>();
    const [reasonForTransfer, setReasonForRransfer] = useState<{ reason_for_transfer: { ID: number, SIFRA: number, SVRHA_TRANSFERA: string, VALUE_SVRHA_TRANSFERA: string } }>();
    const [payoutMethod, setPayoutMethod] = useState<{ payout_method: { ID: number, NACIN_ISPLATE: string, SIFRA: number } }>();

    const [paymentMethodDropdown, setPaymentMethodDropdown] = useState<any>();
    const [reasonForTransferDropdown, setReasonForTransferDropdown] = useState<any>();
    const [payoutMethodDropdown, setPayoutMethodDropdown] = useState<any>();
    const [saveButtonSecondStep, setSaveButtonSecondStep] = useState<boolean>(false);


    // Third step
    const [searchedRecipients, setSearchedRecipients] = useState<any>([]);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    const [message, setMessage] = useState<string>('');

    const [selectedFile, setSelectedFile] = useState<any>();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [selectedBox, setSelectedBox] = useState<any>();
    const [balkanCountry, setBalkanCountry] = useState<any>();
    const [tenRecipient, setTenRecipient] = useState<any>([]);
    const [selectedRecipient, setSelectedRecipient] = useState<any>([]);

    const [saveButtonThirdStep, setSaveButtonThirdStep] = useState<boolean>(false);
    const [selectedRecipientID, setSelectedRecipientID] = useRecoilState(SelectedRecipientID);
    const [payoutMethodAccountEUR, setPayoutMethodAccountEUR] = useRecoilState(payoutMethodAccountEur);

    const [openAmountDialog, setOpenAmountDialog] = useState<boolean>(false);
    const [noteSecondStep, setNoteSecondStep] = useState<string>('');
    const [showInputSecondStep, setShowInputSecondStep] = useState<boolean>(false);
    const [dialogPopupMsg, setDialogPopupMsg] = useState<string>('');
    const [dialogPopupMsgFirstStep, setDialogPopupMsgFirstStep] = useState<string>('');

    const [nameOfRecipient, setNameOfRecipient] = useState<string>('');

    const [openDialogFirstStep, setOpenDialogFirstStep] = useState<boolean>(false);

    const initalTransactionData = {
        id: '',
        amount_payment: 0,
        currencies_of_sending_receipts: '',
        free: 0,
        total_payment: 0,
        total_paid_amount: 0,
        method_of_payment: 0,
        purpose_of_transfer: 0,
        note: '',
        message: '',
        payoutMetod: 0,
        payment_currency: '',
        course_relation: 0,
        commission_currency: '',
        currency_payout: '',
        total_payment_currency: '',
        id_recipient: 0,
        discount_code: 0,
        amount_discount: 0,
        other_expenses: 0,
        picture_instruction_instruction: '',
        picture_of_receipt: '',
        payout_metod: 0
    }

    const [finalDataFirstStep, setFinalDataFirstStep] = useState<ISendTransaction>(initalTransactionData);
    const [newRecipientData, setNewRecipientData] = useState<any>();
    const [profileData, setProfileData] = useState<any>();

    const [, setBalkanCountryRecoil] = useRecoilState(balkanCountryAtom);

    useEffect(() => {
        if (Number(reasonForTransfer?.reason_for_transfer.ID) === 5) {
            setSaveButtonSecondStep(step === 2 && noteSecondStep.trim().length > 0 && showInputSecondStep);
        } else {
            setSaveButtonSecondStep(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, noteSecondStep, showInputSecondStep, reasonForTransfer]);


    useEffect(() => {
        // ID = 3, Account Deposit - EUR
        setPayoutMethodAccountEUR(payoutMethod?.payout_method.ID === 3 || payoutMethod?.payout_method.ID === 2 ? true : false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payoutMethod]);

    useEffect(() => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const userId = JSON.parse(localStorageDataAuth ? localStorageDataAuth : '').id;

        setFinalDataFirstStep(prevState => ({
            ...prevState,
            id: userId
        }));

        getPaymentMethod();
        getReasonForTransfer();
        getPayoutMethod();
        getCurrency();
        getFee();
        getProfileData();
        getBalkanCountry();
        getRecipientForUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRecipientForUser = () => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;
        const userId = JSON.parse(localStorageDataAuth ? localStorageDataAuth : '').id;

        UserTransactions.getRecipientForUser(token, userId).then((response: { data: any; }) => {
            const { data } = response;
            setTenRecipient(data);
            setSearchedRecipients(data);
        }, (error: any) => {
            // console.log('error', error);
        })
    }


    const getBalkanCountry = () => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;

        UserTransactions.getBalkanCountry(token).then((response: { data: any; }) => {
            const { data } = response;
            setBalkanCountry(data);
            setBalkanCountryRecoil(data);

        }, (error: any) => {
            // console.log('error', error);
        })
    }

    const getProfileData = () => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const userId = JSON.parse(localStorageDataAuth ? localStorageDataAuth : '').id;
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null

        ProfileService.getUserInformation(userId, token).then(response => {
            const { data } = response;
            setProfileData(data[0]);
        })
    }

    const getPaymentMethod = () => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;

        UserTransactions.getPaymentMethod(token).then((response: { data: any; }) => {
            const { data } = response;
            setPaymentMethodDropdown(data)
        }, (error: any) => {
            // console.log('error', error);
        })
    }

    const getReasonForTransfer = () => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;

        UserTransactions.getReasonForTransfe(token).then((response: { data: any; }) => {
            const { data } = response;
            setReasonForTransferDropdown(data)
        }, (error: any) => {
            // console.log('error', error);
        })
    }

    const getPayoutMethod = () => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;

        UserTransactions.getPayoutMethod(token).then((response: { data: any; }) => {
            const { data } = response;
            setPayoutMethodDropdown(data)
        }, (error: any) => {
            // console.log('error', error);
        })
    }


    const getCurrency = () => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;

        UserTransactions.getExchangeRate(token).then((response: { data: any; }) => {
            const { data } = response;
            setExchangeRate(data[0].KURS);
        }, (error: any) => {
            // console.log('error', error);
        })
    }

    const getFee = () => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;

        UserTransactions.getFee(token).then((response: { data: any; }) => {
            const { data } = response;
            setFeeArray(data);
        }, (error: any) => {
            // console.log('error', error);
        })
    }

    const handleNext = (stepNumber: number) => {
        if (!burgerMenu) {
            setCurrentStep(stepNumber);
        }
        switch (stepNumber) {
            case 1: {
                setProgress(0);
                setStep(1);
                setNumStepLabel('1/4')
                break;
            }
            case 2: {
                setProgress(30);
                setStep(2);
                setNumStepLabel('2/4')
                setFinalDataFirstStep(prevState => ({
                    ...prevState,
                    total_payment: payoutAmount,
                    amount_payment: payingAmount,
                    course_relation: exchangeRate,
                    currencies_of_sending_receipts: 'AUD / EUR',
                    free: fee,
                    total_paid_amount: totalPayable,
                    amount_discount: discount,
                    commission_currency: 'AUD',
                    payment_currency: 'AUD',
                    currency_payout: 'EUR',
                    total_payment_currency: 'AUD',
                    discount_code: feeCode
                }));

                break;
            }
            case 3: {
                setProgress(70);
                setStep(3);
                setNumStepLabel('3/4')
                // method_of_payment = paymentMethod, purpose_of_transfer = reasonForTransfer, payoutMetod = payoutMethod
                setFinalDataFirstStep(prevState => ({
                    ...prevState,
                    method_of_payment: paymentMethod ? paymentMethod.payoutMetod.SIFRA : paymentMethodDropdown[0].SIFRA,
                    purpose_of_transfer: reasonForTransfer ? reasonForTransfer.reason_for_transfer.SIFRA : reasonForTransferDropdown[0].SIFRA,
                    payout_metod: payoutMethod ? payoutMethod.payout_method.SIFRA : payoutMethodDropdown[0].SIFRA
                }));

                break;
            }
            case 4: {
                setProgress(90);
                setStep(4);
                setNumStepLabel('4/4')
                setFinalDataFirstStep(prevState => ({
                    ...prevState,
                    id_recipient: Number(selectedRows[0] || selectedRecipientID),
                    message: message
                }));
                break;


            }
            case 5: {
                setProgress(100);
                setStep(5);
                sendTransaction();
                break;
            }
            default: {
                //statements; 
                break;
            }
        }
    };

    const sendTransaction = () => {
        // console.log('second step note', noteSecondStep);
        finalDataFirstStep.note = noteSecondStep
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null

        const newUserData = new FormData()
        newUserData.append('IMG_ID', selectedFile);

        ProfileService.updatePicture(newUserData, token).then(response => {
            getProfileData();
            setOpen(false);
        }).catch(error => {
            // console.log(error)
        });
        // console.log('finalDataFirstStep', finalDataFirstStep);

        UserTransactions.sendNewTransaction(token, finalDataFirstStep).then(response => {
            handleClose();
            // setSnackbar({
            //     open: true,
            //     severity: 'success',
            //     message: 'The transaction is successfully sent!'
            // })
            setFinalDataFirstStep(initalTransactionData);

            if (paymentMethod && paymentMethod.payoutMetod && paymentMethod.payoutMetod.SIFRA === 2) {
                console.log('response', response);

                console.log('finalDataFirstStep', finalDataFirstStep);
                const token = 'LF46LEhbf19Esj4nR6s4xjiP4XX6tz4';
                const amount = finalDataFirstStep.total_paid_amount * 100;
                const email = profileData.KOR_EMAIL;
                // Customer Reference
                const reference1 = nameOfRecipient ? nameOfRecipient : '';
                // Invoice No
                const reference2 = response && response.data ? response.data.transactionID : "";
                const url = `https://hpp.mintpayments.com/${token}?email=${email}&amount=${amount}&reference1=${reference1}&reference2=${reference2}`;
                window.open(url, '_blank', 'noopener,noreferrer')
            }

            resetPageData();
            snackbarAutoHide();
        }).catch(error => {
            setFinalDataFirstStep(initalTransactionData);
            resetPageData();
            handleClose();
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        });

    }

    const handleBack = (stepNumber: number) => {
        if (!burgerMenu) {
            setCurrentStep(stepNumber);
        }
        switch (stepNumber) {
            case 1: {
                setProgress(0);
                setStep(1);
                setNumStepLabel('1/4')
                break;
            }
            case 2: {
                setProgress(30);
                setStep(2);
                setNumStepLabel('2/4')
                break;
            }
            case 3: {
                setProgress(70);
                setStep(3);
                setNumStepLabel('3/4')
                break;
            }
            case 4: {
                setProgress(90);
                setStep(4);
                setNumStepLabel('4/4')
                break;
            }
            case 5: {
                setProgress(100);
                setStep(5);
                break;
            }
            default: {
                //statements; 
                break;
            }
        }
    };

    const redirectToTransactionTable = () => {
        history.push(URLRoutes.TransactionListing);
    }

    // First step handle input change
    const handleChangeInputFirstStep = (event: ChangeEvent<{ name: string; value: string }>) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        value = name === 'paying_amount' ? String(value).replace(/\D/g, "") : target.value;

        if (Number(value) < 50) {
            setSaveButtonFirstStep(true);
        }
        if (Number(value) === 0 || value === '') {
            setPayingAmountSideLabel(0.00);
            setTotalPayable(0);
            setFee(0);
            setDiscount(0);
            setOtherCosts(0.00);
            setSaveButtonFirstStep(false);
        }

        if (Number(value) >= 0 && Number(value) <= 999999 && value !== '') {
            let feeTemp = 0;

            // Values exchangeRate, fee and discount needs to be fetched from Backend
            for (let n = 0; n <= feeArray.length; n++) {
                if (Number(value) >= (feeArray[n]?.IZNOSOD * 1) && Number(value) <= (feeArray[n]?.IZNOSDO * 1)) {
                    setFee(feeArray[n]?.IZNOS);
                    setFeeCode(feeArray[n]?.SIFRA);
                    feeTemp = feeArray[n]?.IZNOS;
                }
            }

            const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
            const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;
            let Discount = 0;

            UserTransactions.getDiscount(token, feeTemp).then((response: { data: any; }) => {
                const { data } = response;

                setDiscount(data[0]?.POPUST)
                Discount = data[0]?.POPUST;

                // Payout amount, first line 
                const iznosIsplate = Number(target.value) * exchangeRate;
                const izracunatiIznosIsplate = Math.round(iznosIsplate / 5) * 5;
                if (izracunatiIznosIsplate !== undefined || izracunatiIznosIsplate) {
                    setPayoutAmount(izracunatiIznosIsplate.toFixed(2));
                }


                // Paying amount third line
                const iznosUplateBezTroskova = (Number(izracunatiIznosIsplate) / Number(exchangeRate));
                setPayingAmountSideLabel(iznosUplateBezTroskova.toFixed(2));

                // Total Payable last line
                const izracunatiIznosUplate = ((iznosUplateBezTroskova + feeTemp) - Discount).toFixed(2);
                setTotalPayable(izracunatiIznosUplate);

            }, (error: any) => {
                // console.log('error', error);
                setSaveButtonFirstStep(false);
            })
            setSaveButtonFirstStep(true);
        }

        if (Number(value) >= 0 && Number(value) <= 999999) {
            setPayingAmount(value);
        }

        if (Number(value) >= 10000) {
            setSaveButtonFirstStep(false);
            setOpenDialogFirstStep(true);
            setDialogPopupMsgFirstStep('For transactions over $10,000 please contact our team on (02) 8781 1950 for a more favourable rate. We guarantee bank-beating rates.');
        }

        if (value === '') {
            setPayoutAmount(0);
        }
        if (Number(value) < 50) {
            setSaveButtonFirstStep(false);
        }
    }

    // First step handle input change
    const handleChangeSecondInputFirstStep = (event: ChangeEvent<{ name: string; value: string }>) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        value = name === 'payout_amount' ? String(value).replace(/\D/g, "") : target.value;
        if (Number(value) === 0 || value === '') {
            setPayingAmountSideLabel(0.00);
            setTotalPayable(0);
            setFee(0);
            setDiscount(0);
            setOtherCosts(0.00);
            setSaveButtonFirstStep(false);
            setPayingAmount(0);
        }

        if (Number(value) >= 0 && Number(value) <= 7000 && value !== '') {
            setSaveButtonFirstStep(true);
            let feeTemp = 0;

            // Payout amount, first line 
            const iznosIsplate = Number(target.value);
            const izracunatiIznosIsplate = Math.round(iznosIsplate / 5) * 5;
            const korigovaniIznosUplate = izracunatiIznosIsplate / exchangeRate;

            // Values exchangeRate, fee and discount needs to be fetched from Backend
            for (let n = 0; n <= feeArray.length; n++) {
                if (Number(korigovaniIznosUplate) >= (feeArray[n]?.IZNOSOD * 1) && Number(korigovaniIznosUplate) <= (feeArray[n]?.IZNOSDO * 1)) {
                    setFee(feeArray[n]?.IZNOS);
                    feeTemp = feeArray[n]?.IZNOS;
                }
            }

            const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
            const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;
            let Discount = 0;

            UserTransactions.getDiscount(token, feeTemp).then((response: { data: any; }) => {
                const { data } = response;

                setDiscount(data[0]?.POPUST)
                Discount = data[0]?.POPUST;

                // Payout amount, first line 
                const iznosIsplate = Number(target.value);
                const izracunatiIznosIsplate = Math.round(iznosIsplate / 5) * 5;

                // Paying amount third line
                const iznosUplateBezTroskova = (Number(izracunatiIznosIsplate) / Number(exchangeRate));
                setPayingAmountSideLabel(iznosUplateBezTroskova.toFixed(2));


                // Total Payable last line
                const izracunatiIznosUplate = (iznosUplateBezTroskova + feeTemp - Discount);
                setTotalPayable(izracunatiIznosUplate.toFixed(2));
                setPayingAmount(izracunatiIznosUplate.toFixed(2));

            }, (error: any) => {
                // console.log('error', error);
            })
        }

        if (Number(value) >= 0 && Number(value) <= 999999) {
            setPayoutAmount(value);
        }

        if (Number(value) >= 10000) {
            setOpenDialogFirstStep(true);
            setDialogPopupMsgFirstStep('For transactions over $10,000 please contact our team on (02) 8781 1950 for a more favourable rate. We guarantee bank-beating rates.');
        }
    }

    // Second step handle dropdown change PaymentMethod
    const handleChangePaymentMethod = (event: ChangeEvent<{ name: string; value: string }>) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setPaymentMethod((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    }
    // Second step handle dropdown change ReasonForRransfer
    const handleChangeReasonForRransfer = (event: ChangeEvent<{ name: string; value: any }>) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;
        // ID = 5SVRHA_TRANSFERA: "Other - Please include description - under NOTE"
        setShowInputSecondStep(Number(value.ID) === 5);

        setReasonForRransfer((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    }
    // Second step handle dropdown change PayoutMethod
    const handleChangePayoutMethod = (event: ChangeEvent<{ name: string; value: string }>) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setPayoutMethod((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    }

    const searchRecipient = (event: ChangeEvent<{ name: string; value: string }>) => {
        const target = event.target;
        let value = target.value;

        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const userId = JSON.parse(localStorageDataAuth ? localStorageDataAuth : '').id;
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;
        if (value !== '' && value.length > 0) {
            UserTransactions.getSearchRecipient(token, userId, value).then((response) => {
                const { data } = response;
                setSearchedRecipients(data);
            });
        } else {
            setSearchedRecipients(tenRecipient);
        }
    }

    const capitalizeWord = (word: string) => {
        if (word.length > 0) {
            return word[0].toUpperCase() + word.substring(1).toLowerCase();
        }

    }

    const boxSelection = (item: any) => {
        setSelectedRecipient(item);
        const nameRecipient = item && item.IME_PRIMA && item.PREZIME_PRIMA ? capitalizeWord(item.IME_PRIMA) + " " + capitalizeWord(item.PREZIME_PRIMA) : ""
        setNameOfRecipient(nameRecipient);
        if (payoutMethodAccountEUR) {
            if (item?.SWIFT?.length !== 0 && item?.BANKA?.length !== 0 && item?.BROJ_RACUNA?.length !== 0) {
                const checked = selectedRows.length > 0 ? selectedRows.includes(String(item.ID || item.id)) : false;
                setSelectedRows(!checked ? item.ID ? [String(item.ID)] : [String(item.id)] || selectedRecipientID : []);
                setSelectedBox(!checked ? item : []);
            } else {
                setOpenAmountDialog(true);
                setDialogPopupMsg('The chosen recipient does not have entered the details: account number, SWIFT and bank name.');
            }
        } else {
            const checked = selectedRows.length > 0 ? selectedRows.includes(String(item.ID || item.id)) : false;
            setSelectedRows(!checked ? item.ID ? [String(item.ID)] : [String(item.id)] || selectedRecipientID : []);
            setSelectedBox(!checked ? item : []);
        }
    }

    const isSelected = (datasetId: string | number) => selectedRows.indexOf(String(datasetId)) !== -1;

    const changeFileUploadHandler = (event: any) => {
        const newName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + "." + event.target.files[0].name.split('.').pop();

        let test = renameFile(event.target.files[0], newName);

        setSelectedFile(test);
        setIsFilePicked(true);

        setFinalDataFirstStep(prevState => ({
            ...prevState,
            picture_of_receipt: newName ? newName : Math.random().toString(36).substring(2, 15)
        }));
    };

    const renameFile = (originalFile: File, newName: string) => {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }

    const removeUpload = () => {
        setSelectedFile([]);
        setIsFilePicked(false);
        setFinalDataFirstStep(prevState => ({
            ...prevState,
            picture_of_receipt: null
        }));
    }

    const saveNewRecipientData = async (data: IRecipientData) => {
        // data.date_of_birth = moment(data.date_of_birth, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const filteredData = removeEmpty(data);
        setMessage(data.PORUKA);
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null
        const userId = JSON.parse(localStorageDataAuth ? localStorageDataAuth : '').id;
        filteredData.id = userId;

        let errorText: string | undefined;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(filteredData)
        };

        try {
            const response = await fetch(URL.AddRecipient, requestOptions);
            // check for error response
            if (response.status === 200) {
                const dataResponse: any = response.json();
                handleClose();
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'Recipient added.'
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const testResp = dataResponse.then((someData: { result: any; }) => {
                    data.id = someData.result;
                    boxSelection(data);
                    setNewRecipientData(data);
                    return someData.result;
                })

                // boxSelection(data);
                snackbarAutoHide();
            } else if (response.status === 404) {
                errorText = await response.text();
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: errorText ? errorText : 'Error while creating new recipient!'
                })
                snackbarAutoHide();
            }

        } catch (ex) {
            // console.log('ex', ex);
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        }

    }

    useEffect(() => {
        setSaveButtonThirdStep(selectedRows && selectedRows.length > 0 ? true : false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);

    const removeEmpty = (obj: any) => {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ''));
    }

    const snackbarAutoHide = () => {
        setTimeout(() => {
            setSnackbar({ open: false });
        }, 5000);
    }


    const formatPaymentMethod = (item: number) => {
        for (let index = 0; index < paymentMethodDropdown.length; index++) {
            if (item === paymentMethodDropdown[index].SIFRA) {
                return paymentMethodDropdown[index].NACIN_UPLATE;
            }
        }
    }

    const formatReasonForTransfer = (item: number) => {
        for (let index = 0; index < reasonForTransferDropdown.length; index++) {
            if (item === reasonForTransferDropdown[index].SIFRA) {
                return reasonForTransferDropdown[index].SVRHA_TRANSFERA;
            }
        }
    }

    const formatPayoutMethod = (item: number) => {
        for (let index = 0; index < payoutMethodDropdown.length; index++) {
            if (item === payoutMethodDropdown[index].SIFRA) {
                return payoutMethodDropdown[index].NACIN_ISPLATE;
            }
        }
    }

    const formatBalkanCountry = (item: number) => {
        for (let index = 0; index < balkanCountry.length; index++) {
            if (Number(item) === balkanCountry[index].SIFRA) {
                return balkanCountry[index].NAZIV;
            }
        }
    }

    useEffect(() => {
        if (newRecipientData && newRecipientData.id) {
            handleNext(4);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newRecipientData]);


    const resetPageData = () => {
        setPayingAmount(0);
        setPayoutAmount(0);
        setNewRecipientData([]);
        setSearchedRecipients([]);
        setSelectedRows([]);
        setMessage('');
        setSelectedFile(null);
        setIsFilePicked(false);
        setSelectedBox([]);
        setSaveButtonFirstStep(false);
        setSaveButtonThirdStep(false);
    }

    const makeAnother = () => {
        window.location.reload();
    }

    useEffect(() => {
        setBurgerMenu(desktopWidth < 640 ? true : false);
    }, [desktopWidth, height]);

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const editRecipient = (event: any, item: any) => {
        event.stopPropagation()
        handleOpenEditRecipient();
        setSelectedRecipient(item);
    }

    const replacePropertyNames = (data: any) => {
        return {
            first_name: data.IME_PRIMA,
            middleName: data.SREDNJE_IME,
            last_name: data.PREZIME_PRIMA,
            date_of_birth: data.DATUM_RODJENJA_PRIMA,
            email: data.EMAIL_PRIMA,
            mobile_number: data.DR_BR_TEL_PRIMA,
            home_number: data.BR_TEL_PRIMA,
            country: data.DRZAVA_PRIMA,
            recipient_country: data.DRZAVA_PRIMA_NAZIV,
            city: data.GRAD_PRIMA,
            address: data.ADRESA_PRIMA,
            zip_code: data.POST_BR_PRIMA,
            account_number: data.BROJ_RACUNA,
            swift: data.SWIFT,
            bank: data.BANKA
        };
    }

    const editRecipientData = async (data: IEditRecipientlData) => {
        // data.DATUM_RODJENJA_PRIMA = moment(data.DATUM_RODJENJA_PRIMA, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const tempData = replacePropertyNames(data)
        const filteredData = removeEmpty(tempData);
        setMessage(data.PORUKA);
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null
        const userId = JSON.parse(localStorageDataAuth ? localStorageDataAuth : '').id;
        if (typeof filteredData.date_of_birth === 'object' && filteredData.date_of_birth !== null) {
            filteredData.date_of_birth = moment(filteredData.date_of_birth).format('DD/MM/YYYY');
        }


        filteredData.ID = data.ID;



        let errorText: string | undefined;
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(filteredData)
        };

        try {
            const response = await fetch(URL.EditRecipient + '/' + data.ID, requestOptions);
            // check for error response
            if (response.status === 200) {
                const dataResponse: any = response.json();
                getRecipientForUser();
                handleCloseEditRecipient();
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'Recipient Edited.'
                })

                // // eslint-disable-next-line @typescript-eslint/no-unused-vars
                // const testResp = dataResponse.then((someData: { result: any; }) => {
                //     data.ID = someData.result;
                //     return someData.result;
                // })

                // setNewRecipientData(data);
                snackbarAutoHide();
            } else if (response.status === 404) {
                errorText = await response.text();
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: errorText ? errorText : 'Error while editing recipient!'
                })
                snackbarAutoHide();
            }

        } catch (ex) {
            // console.log('ex', ex);
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        }
    }

    const onCloseDialogAmount = () => {
        setOpenAmountDialog(false);
        setOpenEditRecipient(true);
    }

    const onCloseDialogFirstStep = () => {
        setOpenDialogFirstStep(false);
    }

    const handleChangeNoteSecondStep = (event: ChangeEvent<{ name?: string; value: any }>) => {
        const target = event.target;
        let value = target.value;
        setNoteSecondStep(value);
    }

    return <div className={styles.money_transfer_wrapper}>

        <div className={styles.menu_wrapper}></div>
        <div className={styles.menu_title}>Money Transfer</div>
        <div className={styles.title}>STEP {numStepLabel}</div>
        <Box className={styles.progress_bar_width}>
            <LinearProgressWithLabel value={progress} />
        </Box>
        {step === 1 ?
            <div className={`${styles.first_step}`}>
                <div className={styles.question}>How much would you like to send?</div>
                <div className={styles.money_transfer_first_step}>
                    <div>
                        <div className={styles.label}>You send:</div>
                        <TextField autoComplete='off' className={styles.input} name={'paying_amount'} variant="outlined" placeholder={"Paying amount"} type='text'
                            value={payingAmount ? payingAmount : ''}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputFirstStep(e)}
                        />
                        <div className={styles.label}>They receive:</div>
                        <TextField autoComplete='off' className={styles.input} name={'payout_amount'} variant="outlined" placeholder={"Payout amount"} type='text'
                            value={payoutAmount ? payoutAmount : ''}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeSecondInputFirstStep(e)} />
                    </div>
                    <div className={styles.calc_section}>
                        <div className={`${styles.labels_wraper} ${styles.border}`}>
                            <div className={styles.title_calc}>Payout amount:</div>
                            <div className={styles.value_calc}>{parseFloat(payoutAmount).toFixed(2)} EUR</div>
                        </div>
                        <div className={styles.labels_wraper}>
                            <div className={styles.title_calc}>AUD/EUR:</div>
                            <div className={styles.value_calc}>1 AUD = {exchangeRate} EUR</div>
                        </div>

                        <div className={styles.labels_wraper}>
                            <div className={styles.title_calc}>Paying amount:</div>
                            <div className={styles.value_calc}>{parseFloat(payingAmountSideLabel).toFixed(2)} AUD</div>
                        </div>

                        <div className={styles.labels_wraper}>
                            <div className={styles.title_calc}>Fee:</div>
                            <div className={styles.value_calc}>+ {fee.toFixed(2)} AUD</div>
                        </div>

                        <div className={styles.labels_wraper}>
                            <div className={styles.title_calc}>Discount:</div>
                            <div className={styles.value_calc}>- {discount.toFixed(2)} AUD</div>
                        </div>

                        <div className={`${styles.labels_wraper} ${styles.border}`}>
                            <div className={styles.title_calc}>Other costs:</div>
                            <div className={styles.value_calc}>{parseFloat(otherCosts).toFixed(2)} AUD</div>
                        </div>

                        <div className={`${styles.labels_wraper} ${styles.border}`}>
                            <div className={styles.title_calc}>Total Payable:</div>
                            <div className={styles.value_calc}>{parseFloat(totalPayable).toFixed(2)} AUD</div>
                        </div>
                    </div>
                </div>
                <div className={styles.note_info}>*NOTE – The payout amount must be rounded up to the nearest 5 EUR</div>
                <div className={styles.note_info}>*NOTE – The minimum amount to send is 50 AUD</div>
                <div className={styles.button_section}>
                    <div><button className={`${styles.button} ${styles.visibility_hidden} ${step === 1 ? styles.disabled : ''}`} >BACK</button></div>
                    <div><button className={`${styles.button} ${!saveButtonFirstStep ? styles.disabled : ''}`} onClick={() => handleNext(2)}>NEXT</button></div>
                </div>
            </div> : null
        }

        {
            step === 2 ?
                <div className={`${styles.second_step}`}>
                    <div className={styles.question}>Payment Method</div>
                    <div className={styles.money_transfer}>
                        <div className={styles.label}>Payment Method:</div>
                        <div className={styles.input}>
                            <TextField name='payoutMetod' variant="outlined" select value={paymentMethod ? paymentMethod.payoutMetod : paymentMethodDropdown[0]} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangePaymentMethod(e)}>
                                {paymentMethodDropdown.map((item: any, index: any) => (
                                    <MenuItem key={index} className={`status_option ${item.cssClass} ${styles.jobs_filter_status}`} value={item}>{item.NACIN_UPLATE}</MenuItem>
                                ))
                                }
                            </TextField>
                        </div>
                        <div className={styles.label}>Reason for Transfer:</div>
                        <div className={styles.input}>
                            <TextField name='reason_for_transfer' variant="outlined" select value={reasonForTransfer ? reasonForTransfer.reason_for_transfer : reasonForTransferDropdown[0]} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeReasonForRransfer(e)}>
                                {reasonForTransferDropdown.map((item: any, index: any) => (
                                    <MenuItem key={index} className={`status_option ${item.cssClass} ${styles.jobs_filter_status}`} value={item}>{item.SVRHA_TRANSFERA}</MenuItem>
                                ))
                                }
                            </TextField>
                        </div>
                        {
                            showInputSecondStep ?
                                <div className={styles.input}>
                                    <TextField name='note_transfer' variant="outlined" value={noteSecondStep} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeNoteSecondStep(e)} inputProps={{ maxLength: 255 }}>
                                    </TextField>
                                </div>
                                : null
                        }
                        <div className={styles.label}>Payout Method:</div>
                        <div className={styles.input}>
                            <TextField name='payout_method' variant="outlined" select value={payoutMethod ? payoutMethod.payout_method : payoutMethodDropdown[0]} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangePayoutMethod(e)}>
                                {payoutMethodDropdown.map((item: any, index: any) => (
                                    <MenuItem key={index} className={`status_option ${item.cssClass} ${styles.jobs_filter_status}`} value={item}>{item.NACIN_ISPLATE}</MenuItem>
                                ))
                                }
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.button_section}>
                        <div><button className={`${styles.button}`} onClick={() => handleBack(1)}>BACK</button></div>
                        <div><button className={`${styles.button} ${!saveButtonSecondStep ? styles.disabled : ''}`} onClick={() => handleNext(3)}>NEXT</button></div>
                    </div>
                </div> : null
        }

        {
            step === 3 ?
                <div className={`${styles.third_step}`}>
                    <div className={styles.question}>Choose a recipient</div>
                    <TextField autoComplete='off' className={styles.input} name={'search_recipient'} variant="outlined" placeholder={"Search for recipient's name"} type='text'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => searchRecipient(e)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment className={styles.search_icon} position="start">
                                    <SearchIcon />
                                </InputAdornment>)
                        }} />
                    <div className={styles.recipients}>
                        <div className={styles.title}> choose recent recipient </div>
                        <div className={styles.box_section}>
                            <div className={styles.box_create_new} onClick={handleOpen}>
                                <div className={`icon icon_add`}></div>
                                <div className={styles.label}>Add New</div>
                            </div>
                            {
                                newRecipientData ?
                                    <Tooltip classes={{ tooltip: classes.customTooltip }} placement="left-start" title={
                                        <React.Fragment>
                                            <div style={{ textTransform: 'capitalize' }}>Recipient Name: {newRecipientData ? newRecipientData.first_name : ''}  {newRecipientData ? newRecipientData.last_name : ''}</div>
                                            <div>Date of Birth: {formatDate(newRecipientData ? newRecipientData.date_of_birth : '')}</div>
                                            <div>Email: {newRecipientData && newRecipientData.email ? newRecipientData.email : '/'}</div>
                                            <div>Mobile Number: {newRecipientData && newRecipientData.mobile_number ? newRecipientData.mobile_number : '/'}</div>
                                            <div>Home Number: {newRecipientData && newRecipientData.home_number ? newRecipientData.home_number : '/'}</div>
                                            <div style={{ textTransform: 'capitalize' }}>Country: {formatBalkanCountry(newRecipientData && newRecipientData.country ? newRecipientData.country : 0)}</div>
                                            <div style={{ textTransform: 'capitalize' }}>City: {newRecipientData && newRecipientData.city ? newRecipientData.city : '/'}</div>
                                            <div style={{ textTransform: 'capitalize' }}>Address: {newRecipientData && newRecipientData.address ? newRecipientData.address : '/'}</div>
                                            <div>Post code: {newRecipientData && newRecipientData.post_code ? newRecipientData.post_code : '/'}</div>
                                            <div>Account number(IBAN): {newRecipientData && newRecipientData.account_number ? newRecipientData.account_number : '/'}</div>
                                            <div>SWIFT: {newRecipientData && newRecipientData.swift ? newRecipientData.swift : ''}</div>
                                            <div style={{ textTransform: 'capitalize' }}>Bank name: {newRecipientData && newRecipientData.bank ? newRecipientData.bank : '/'}</div>
                                        </React.Fragment>}>

                                        <div key={-1} onClick={() => boxSelection(newRecipientData)} className={`${styles.box_recipient} ${isSelected(newRecipientData.ID || newRecipientData.id) ? styles.box_selected : styles.box_not_selected}`}>
                                            <div className={`icon icon_recipient_user ${styles.icon_section}`}></div>
                                            <div className={styles.recipient_name}>{newRecipientData ? newRecipientData.first_name : ''}  {newRecipientData ? newRecipientData.last_name : ''}</div>
                                            <div className={styles.recipient_location}>{newRecipientData ? newRecipientData.city : ''}</div>
                                            <div className={styles.recipient_bank}>{newRecipientData ? newRecipientData.bank : ''}</div>
                                            <div className={styles.recipient_bank}>{newRecipientData ? newRecipientData.email : ''}</div>
                                        </div>
                                    </Tooltip>
                                    : null
                            }

                            {searchedRecipients.map((item: any, index: any) => (
                                <Tooltip classes={{ tooltip: classes.customTooltip }} placement="left-start" title={
                                    <React.Fragment>

                                        <div style={{ textTransform: 'capitalize' }}>Recipient Name: {item ? item.IME_PRIMA : ''}  {item ? item.PREZIME_PRIMA : ''}</div>
                                        <div>Date of Birth: {formatDate(item ? item.DATUM_RODJENJA_PRIMA : '')}</div>
                                        <div>Email: {item && item.EMAIL_PRIMA ? item.EMAIL_PRIMA : '/'}</div>
                                        <div>Mobile Number: {item && item.BR_TEL_PRIMA ? item.BR_TEL_PRIMA : '/'}</div>
                                        <div>Home Number: {item && item.DR_BR_TEL_PRIMA ? item.DR_BR_TEL_PRIMA : '/'}</div>
                                        <div style={{ textTransform: 'capitalize' }}>Country: {formatBalkanCountry(item && item.DRZAVA_PRIMA ? item.DRZAVA_PRIMA : 0)}</div>
                                        <div style={{ textTransform: 'capitalize' }}>City: {item && item.GRAD_PRIMA ? item.GRAD_PRIMA : '/'}</div>
                                        <div style={{ textTransform: 'capitalize' }}>Address: {item && item.ADRESA_PRIMA ? item.ADRESA_PRIMA : '/'}</div>
                                        <div>Post code: {item && item.POST_BR_PRIMA ? item.POST_BR_PRIMA : '/'}</div>
                                        <div>Account number(IBAN): {item && item.BROJ_RACUNA ? item.BROJ_RACUNA : '/'}</div>
                                        <div>SWIFT: {item && item.SWIFT ? item.SWIFT : ''}</div>
                                        <div style={{ textTransform: 'capitalize' }}>Bank name: {item && item.BANKA ? item.BANKA : '/'}</div>
                                    </React.Fragment>}>

                                    <div key={index} onClick={() => boxSelection(item)} className={`${styles.box_recipient} ${isSelected(item.ID) ? styles.box_selected : styles.box_not_selected}`}>
                                        <div className={styles.edit_icon_wrapper}>
                                            <span className={`icon_svg icon_edit ${styles.edit_icon_position}`} onClick={(event) => editRecipient(event, item)}></span>
                                        </div>
                                        <div className={`icon icon_recipient_user ${styles.icon_section}`}></div>
                                        <div className={styles.recipient_name}>{item ? item.IME_PRIMA : ''}  {item ? item.PREZIME_PRIMA : ''}</div>
                                        <div className={styles.recipient_location}>{item ? item.GRAD_PRIMA : ''}</div>
                                        <div className={styles.recipient_bank}>{item ? item.BANKA : ''}</div>
                                        <div className={styles.recipient_bank}>{item ? item.EMAIL_PRIMA : ''}</div>
                                    </div>
                                </Tooltip>
                            ))
                            }
                            {!newRecipientData ?
                                searchedRecipients.length === 0 ?
                                    <div className={styles.no_results}> No results </div> : null
                                : null
                            }
                        </div>
                    </div>
                    <div className={styles.button_section}>
                        <div><button className={`${styles.button}`} onClick={() => handleBack(2)}>BACK</button></div>
                        <div><button className={`${styles.button} ${!saveButtonThirdStep ? styles.disabled : ''}`} onClick={() => handleNext(4)}>NEXT</button></div>
                    </div>

                    <Modal
                        open={open}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <div><AddRecipient close={() => handleClose()} saveNewRecipientData={saveNewRecipientData} /></div>
                    </Modal>

                    <Modal
                        open={openEditRecipient}
                        aria-labelledby="modal-modal-title-edit"
                        aria-describedby="modal-modal-description-edit">
                        <div><EditRecipient closeEdit={() => handleCloseEditRecipient()} selectedRecipientData={selectedRecipient} editRecipientData={editRecipientData} /></div>
                    </Modal>

                </div> : null
        }

        {
            step === 4 ?
                <div className={`${styles.fourth_step}`}>
                    <div className={styles.question}>Review information before sending</div>

                    <div className={styles.boxes_section}>

                        <div className={styles.wrapper}>

                            <div className={styles.info_section}>
                                <div className={styles.label}>Sent by</div>
                                <div className={styles.icon_section}>
                                    <div className={`icon_svg icon_recipient_user`}></div>
                                </div>
                                <div className={styles.information}>
                                    <div className={styles.full_name}>{profileData?.KOR_IME + " " + profileData?.KOR_PREZIME}</div>
                                    <div className={styles.location}>{profileData?.GRAD + " " + profileData?.DRZAVA}</div>
                                    <div className={styles.email}>{profileData?.KOR_EMAIL}</div>
                                </div>
                            </div>

                            <div className={styles.info_section}>
                                <div className={styles.label}>Recipient</div>
                                <div className={styles.icon_section}>
                                    <div className={`icon_svg icon_recipient_user`}></div>
                                </div>
                                <div className={styles.information}>
                                    <div className={styles.full_name}> {selectedBox && selectedBox.IME_PRIMA ? selectedBox.IME_PRIMA : selectedBox.first_name} {selectedBox && selectedBox.PREZIME_PRIMA ? selectedBox.PREZIME_PRIMA : selectedBox.last_name}</div>
                                    <div className={styles.location}>{selectedBox && selectedBox.GRAD_PRIMA ? selectedBox.GRAD_PRIMA : selectedBox.city} {selectedBox && selectedBox.DRZAVA_PRIMA ? formatBalkanCountry(selectedBox.DRZAVA_PRIMA) : formatBalkanCountry(selectedBox.country)}</div>
                                    <div className={styles.email}>{selectedBox && selectedBox.EMAIL_PRIMA ? selectedBox.EMAIL_PRIMA : selectedBox.email}</div>
                                </div>
                            </div>


                            <div className={styles.info_section}>
                                <div className={styles.label}>Amount</div>
                                <div className={styles.icon_section}>
                                    <div className={`icon_svg icon_australia`}></div>
                                </div>
                                <div className={styles.information}>
                                    <div className={styles.full_name}>{finalDataFirstStep.total_paid_amount} {finalDataFirstStep.payment_currency}</div>
                                </div>
                            </div>

                            <div className={styles.info_section}>
                                <div className={styles.label}>Transfer</div>
                                <div className={styles.icon_section}>
                                    <div className={`icon icon_eu`}></div>
                                </div>
                                <div className={styles.information}>
                                    <div className={styles.full_name}>{finalDataFirstStep.total_payment ? Number(finalDataFirstStep.total_payment) : 0} {finalDataFirstStep.currency_payout}</div>
                                </div>
                            </div>

                            <div className={styles.info_section}>
                                <div className={styles.label}>Payment Method</div>
                                <div className={styles.icon_section}></div>
                                <div className={styles.information}>
                                    <div className={styles.full_name}>{formatPaymentMethod(finalDataFirstStep.method_of_payment)}</div>
                                </div>
                            </div>

                            <div className={styles.info_section}>
                                <div className={styles.label}>Reason for Transfer</div>
                                <div className={styles.icon_section}></div>
                                <div className={styles.information}>
                                    <div className={styles.full_name}>{formatReasonForTransfer(finalDataFirstStep.purpose_of_transfer)}</div>
                                </div>
                            </div>

                            <div className={styles.info_section}>
                                <div className={styles.label}>Payout Method</div>
                                <div className={styles.icon_section}></div>
                                <div className={styles.information}>
                                    <div className={styles.full_name}>{formatPayoutMethod(finalDataFirstStep.payout_metod)}</div>
                                </div>
                            </div>
                        </div>

                        {
                            paymentMethod && paymentMethod.payoutMetod && paymentMethod.payoutMetod.SIFRA === 2 ?
                                null :
                                <div className={styles.wrapper_payment}>

                                    <div className={styles.info_section}>
                                        <div className={styles.title_icon_wrapper}>
                                            <div className={styles.big_title}>Please make a payment to the following account at Bendigo Bank:</div>
                                            <div>
                                                <Tooltip classes={{ tooltip: classes.customTooltip }} title="You will now be required to access your internet banking to make a transfer to the bank account stated below.If you need further assistance, please call 02/878 1950 prior to making a payment.">
                                                    <HelpIcon className={styles.help_icon} />
                                                </Tooltip>
                                            </div>
                                        </div>

                                        <div className={styles.information}>
                                            <div className={styles.title}>Account Name:</div>
                                            <div className={styles.text}>BEO-Export Australia Pty Ltd - Trust Account</div>
                                        </div>
                                        <div className={styles.information}>
                                            <div className={styles.title}>BSB:</div>
                                            <div className={styles.text}>633000</div>
                                        </div>
                                        <div className={styles.information}>
                                            <div className={styles.title}>Account Number:</div>
                                            <div className={styles.text}>126370600</div>
                                        </div>

                                        <div className={styles.autocomplete_container_img_section}>
                                            <div className={styles.input_label_img_section}>File Upload (Payment receipt) Optional</div>
                                            <div className={styles.file_upload_wrapper}>
                                                <label htmlFor="upload-photo">
                                                    <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" onChange={(e) => changeFileUploadHandler(e)} accept=".png, .jpg, .jpeg, .pdf" />
                                                    <Fab color="secondary" size="small" component="span" aria-label="add" variant="extended">
                                                        <AddIcon /> Upload Receipt
                                                    </Fab>
                                                </label>
                                            </div>
                                            {
                                                isFilePicked ?
                                                    <div className={styles.file_upload}>
                                                        <div className={styles.input_label}>File Name:</div>
                                                        <div className={styles.file_upload_name}>{selectedFile?.name} <span onClick={removeUpload}> <HighlightOffIcon /> </span> </div>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                        }



                    </div>


                    <div className={styles.button_section}>
                        <div><button className={`${styles.button}`} onClick={() => handleBack(3)}>BACK</button></div>
                        <div><button className={styles.button} onClick={() => handleNext(5)}>SEND</button></div>
                    </div>
                </div> : null
        }


        {
            step === 5 ?
                <div className={`${styles.fifth_step}`}>
                    <div className={styles.title}>The transaction has been submitted!</div>
                    <div className={styles.subtitle}>You can check the status of the payment in the transaction listing.</div>
                    <div className={styles.button_section}>
                        <div><button className={`${styles.button}`} onClick={() => redirectToTransactionTable()}>Check now</button></div>
                        <div><button className={styles.button} onClick={() => makeAnother()}>Make another</button></div>
                    </div>
                </div>
                : null
        }
        <SnackbarAlert open={snackbar.open} severity={snackbar.severity} message={snackbar.message} />
        <DialogPopup openDialog={openAmountDialog} confirm={onCloseDialogAmount} message={dialogPopupMsg} />

        <PopupFirstStep openDialogPopupFirstStep={openDialogFirstStep} confirmPopupFirstStep={onCloseDialogFirstStep} messagePopupFirstStep={dialogPopupMsgFirstStep} />
    </div>
}

export default MoneyTransfer;