import React, { useEffect, useState } from 'react'
import ProfileService from '../../services/Profile';
import { IProfilData } from '../../interface/TransferMoney';
import { ISnackbar } from '../../interface/Snackbar';
import { LocalStorage } from '../../enums/LocalStorage';
import SnackbarAlert from '../../components/SnackbarAlert/SnackbarAlert';
import { Modal } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { URLRoutes } from '../../enums/Routes';
import ResetPassModal from '../../components/ResetPassModal/ResetPassModal';

const ResetPassPage = () => {
    const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false, severity: 'success', message: '' });
    const [openModal, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const history = useHistory();

    useEffect(() => {
        handleOpen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const saveNewPassValue = (parameters: { LOZINKA: string }) => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const userId = JSON.parse(localStorageDataAuth ? localStorageDataAuth : '').id;
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null

        ProfileService.updateForgotPass(parameters.LOZINKA, userId, token).then(response => {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'Password updated.'
            })

            setOpen(false);
            const localStorageDataAuthJSON: any = JSON.parse(localStorageDataAuth ? localStorageDataAuth : '');
            localStorageDataAuthJSON.forgotPass = false;

            localStorage.setItem('auth', JSON.stringify(localStorageDataAuthJSON));
            history.push(URLRoutes.MoneyTransfer);
            snackbarAutoHide();
        }).catch(error => {
            setOpen(false);
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        });
    }

    const snackbarAutoHide = () => {
        setTimeout(() => {
            setSnackbar({ open: false });
        }, 5000);
    }

    return (<div>
        <Modal
            open={openModal}
            aria-labelledby="modal-profile-title"
            aria-describedby="modal-profile-description">
            <div><ResetPassModal close={() => handleClose()} saveNewPassValue={saveNewPassValue} /></div>
        </Modal>
        <SnackbarAlert open={snackbar.open} severity={snackbar.severity} message={snackbar.message} />
    </div>)
}

export default ResetPassPage;