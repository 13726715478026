import axios, { AxiosResponse } from 'axios';
import LocalStorage from '../LocalStorage';
import { URLRoutes } from '../../enums/Routes';
import Auth from '../Auth';

const config = axios.create({
    // baseURL: 'http://localhost:3005'
     baseURL: 'https://apibeoexport.com.au.beoexport.com.au'
});

const refreshTokenAPI_URL = '/user/refresh';

config.interceptors.request.use(function (config) {
    // const authData = localStorage.getItem('auth');
    // if (authData) {
    //     const token = JSON.parse(authData)['token'];
    //     const refreshToken = LocalStorage.getRefreshToken();
    //     config.headers['api-token'] = config.url === refreshTokenAPI_URL ? refreshToken : token;
    // }

    // if (config.params) {
    //     config.params = decamelizeKeys(config.params);
    // }
    // if (config.data) {
    //     config.data = decamelizeKeys(config.data);
    // }

    return config;
});

config.interceptors.response.use((response: AxiosResponse) => {
    // if (response.data) {
    //     response.data = camelizeKeys(response.data);
    // }
    if (response.status === 403) {
        Auth.logout();
    }

    return response;
}, function (error) {
    const originalRequest = error.config;

    if (error.response) {
        if (error.response.status === 403) {
            Auth.logout();
        }
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = LocalStorage.getRefreshToken();
            // return axios.post('localhost:3005' + refreshTokenAPI_URL, null, { headers: { "api-token": refreshToken } })
            return axios.post('https://beoexport.com.au' + refreshTokenAPI_URL, null, { headers: { "api-token": refreshToken } })
                .then(res => {
                    if (res.status === 200) {
                        LocalStorage.setAuthData(JSON.stringify(res.data));
                        axios.defaults.headers['api-token'] = LocalStorage.getAccessToken();
                        window.location.href = window.location.pathname;
                        return axios(originalRequest);
                    }
                }, error => {
                    window.location.href = URLRoutes.Login;
                    return Promise.reject(error);
                })
        }
    }

    return Promise.reject(error);
});

export default config;