import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { formatDate } from '../../../../helpers/DateTimeHelper';
import styles from './UserView.module.scss';
import { URL } from "../../../../enums/Url";
import { Box, Modal } from '@material-ui/core';

const UserView = () => {
    const history = useHistory();
    const [userData, setUserData] = useState<any>();
    const [openModal, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 0,
        boxShadow: 24,
        p: 4,
    };
    const [showImgUrl, setShowImageUrl] = useState<any>();


    useEffect(() => {
        const recData: any = history.location.state;
        if (recData) {
            setUserData(recData.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location]);

    const formatAuthorization = (item: number) => {
        if (item === 1) {
            return 'User'
        } else if (item === 3) {
            return 'Admin'
        }
    }

    const formatUnlocked = (item: string) => {
        if (item === 'D') {
            return 'Yes'
        } else if (item === 'N') {
            return 'No'
        }
    }

    const openImg = (img: string) => {
        handleOpen();
        setShowImageUrl(img);
    }

    return <div className={styles.transaction_view_wrapper}>
        <div className={styles.menu_wrapper}></div>
        <div className={styles.go_back_button} onClick={history.goBack}> <span className={`icon_svg icon_arrow ${styles.span_icon}`}></span> Go Back </div>
        <div className={styles.header_section}>
            <div className={styles.title}>User: {userData && userData.KOR_IME && userData.KOR_PREZIME ? userData.KOR_IME + " " + userData.KOR_PREZIME : '/'}</div>
            <div className={styles.subtitle}>Below are expanded user details.</div>
        </div>
        <div className={styles.information_wrapper}>
            <div className={styles.infomation_section}>
                <div className={styles.left_section}>
                    <div className={styles.circle_placeholder}>J D</div>
                    <div style={{ textTransform: 'capitalize' }} className={styles.full_name}> {userData && userData.KOR_IME && userData.KOR_PREZIME ? userData.KOR_IME + " " + userData.KOR_PREZIME : '/'}</div>
                    <div className={styles.email}>{userData && userData.KOR_EMAIL ? userData.KOR_EMAIL : '/'}</div>
                    <div className={styles.country}>{userData && userData.DRZAVA_NAZIV ? userData.DRZAVA_NAZIV : '/'}</div>
                    <div className={styles.documents_pictures_wrapper}>
                        {userData && userData.IMG_ID ?
                            <div>
                                <div className={styles.circle_placeholder_doc} onClick={() => openImg(`${URL.BaseURL}/uploads/${userData && userData.IMG_ID ? userData.IMG_ID : ''}`)}>
                                    <img className={styles.image_wrapper} src={`${URL.BaseURL}/uploads/${userData && userData.IMG_ID ? userData.IMG_ID : ''}`} alt="" />
                                </div>
                                <div className={styles.title}>Photo ID of the document</div>
                            </div>
                            : null
                        }

                        {userData && userData.IMG_ID_2 ?
                            <div>
                                <div className={styles.circle_placeholder_doc} onClick={() => openImg(`${URL.BaseURL}/uploads/${userData && userData.IMG_ID_2 ? userData.IMG_ID_2 : ''}`)}>
                                    <img className={styles.image_wrapper} src={`${URL.BaseURL}/uploads/${userData && userData.IMG_ID_2 ? userData.IMG_ID_2 : ''}`} alt="" />
                                </div>
                                <div className={styles.title}>Photo ID of the document</div>
                            </div>
                            : null
                        }
                    </div>

                </div>
                <div className={styles.right_section}>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>User</div>
                        <div className={styles.colon}>:</div>
                        <div style={{ textTransform: 'capitalize' }} className={styles.value}>{userData && userData.KOR_IME && userData.KOR_PREZIME ? userData.KOR_IME + " " + userData.KOR_PREZIME : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>ID</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{userData && userData.USERID ? userData.USERID : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Member No.</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{userData && userData.CLBROJ ? userData.CLBROJ : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Address</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{userData && userData.ADRESA ? userData.ADRESA : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>City</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{userData && userData.GRAD ? userData.GRAD : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Post code</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{userData && userData.POST_BR ? userData.POST_BR : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Country</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{userData && userData.DRZAVA_NAZIV ? userData.DRZAVA_NAZIV : userData && userData.DRZAVA ? userData.DRZAVA : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} `}>
                        <div className={styles.title}>Date of Birth</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{userData && userData.DAT_RODJ ? formatDate(userData.DAT_RODJ) : '/'}</div>
                    </div>


                </div>
                <div className={styles.border_line}></div>
                <div className={styles.right_section}>
                    {/* <div className={`${styles.section_title_second} ${styles.section_title}`}>Transaction Information</div> */}
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}> Mobile Number</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{userData && userData.BR_TEL ? userData.BR_TEL : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Telephone number</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{userData && userData.DRUGI_BR_TEL ? userData.DRUGI_BR_TEL : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Profession</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{userData && userData.ZANIMANJE ? userData.ZANIMANJE : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Type of document</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{userData && userData.VRSTA_DOKUMENTA ? userData.VRSTA_DOKUMENTA : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Document number</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{userData && userData.ID_DOKUMENTA ? userData.ID_DOKUMENTA : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>ID Expiry Date</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{userData && userData.DATUM_ISTEKA_ID ? formatDate(userData.DATUM_ISTEKA_ID) : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Authorization</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{userData && userData.OVLAST ? formatAuthorization(userData.OVLAST) : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Unlocked</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{userData && userData.OTKLJUCAN ? formatUnlocked(userData.OTKLJUCAN) : '/'}</div>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-profile-title"
            aria-describedby="modal-profile-description">

            <Box sx={style}>
                <div className={styles.modal_wrapper}>
                    <img src={`${showImgUrl}`} alt="" />
                </div>
            </Box>
        </Modal>
    </div>
}

export default UserView;