import { DialogContent, DialogContentText, DialogActions, Button, Dialog } from "@material-ui/core";
import React from 'react';

interface IDialog {
    openDialogPopupFirstStep: boolean,
    confirmPopupFirstStep: () => void,
    messagePopupFirstStep: string
}


const PopupFirstStep = (props: IDialog) => {
    const { openDialogPopupFirstStep, confirmPopupFirstStep, messagePopupFirstStep } = props;

    return <div className="dialog_wrapper">
        <Dialog
            open={openDialogPopupFirstStep}
            keepMounted
        >
            <DialogContent>
                <DialogContentText style={{
                    fontWeight: '500',
                    fontSize: "16px",
                    color: "#04539B",
                    textAlign: "center"
                }}>
                    {messagePopupFirstStep}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={{
                    color: "#04539B"
                }} onClick={confirmPopupFirstStep}>OK</Button>
            </DialogActions>
        </Dialog>
    </div>
}

export default PopupFirstStep;