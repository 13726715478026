import React, { useEffect, useState, MouseEvent } from 'react';
import styles from './UserTransactionTable.module.scss'
import { Table, TableHead, TableRow, TableCell, TableBody, TablePagination, TableSortLabel, Tooltip } from '@material-ui/core';
import { PageSizeParameters } from '../../../enums/Enums';
import { ITransactionTableUser } from '../../../interface/Table';
import { useHistory, useLocation } from 'react-router-dom';
import { URLRoutes } from '../../../enums/Routes';
import { IHeaderCells } from '../../../interface/Table';
import BeoExportTableContainer from '../BeoExportTableContainer/BeoExportTableContainer';
import { LocalStorage } from '../../../enums/LocalStorage';
import UserTransactions from '../../../services/UserTransactions';
import { formatDate } from '../../../helpers/DateTimeHelper';
import Loading from '../../Loading/Loading';

interface IUserTransactionTable {
    filterParameters?: any,
}

const UserTransactionTable = (props: IUserTransactionTable) => {
    type Order = 'asc' | 'desc';
    const [data, setJobsData] = useState<ITransactionTableUser[]>([]);
    const history = useHistory();
    const location = useLocation();
    const queryString = require('query-string');
    const [page, setPage] = useState<number>(queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0);
    const [count, setCount] = useState<number>(0);
    const [order, setOrder] = useState<Order>(queryString.parse(location.search).order_by ? queryString.parse(location.search).order_by.split(' ')[1] : 'desc');
    const [orderBy, setOrderBy] = useState<string>(queryString.parse(location.search).order_by ? queryString.parse(location.search).order_by.split(' ')[0] : 'DATUM_TRANSFERA');
    const [loading, setLoading] = useState<boolean>(false);

    const headCells = [
        { key: 'RECIPEINT', label: 'Recipient' },
        { key: 'TOTAL_PAYABLE', label: 'AUD' }, 
        { key: 'UKP_ISPLATA', label: 'EUR' },
        { key: 'NACIN_ISPLATE', label: 'Payment Method' },
        { key: 'SVRHA_TRANSFERA', label: 'Transfer Reason' },
        { key: 'PAYMENT_METOD', label: 'Payout Method' },
        { key: 'DATUM_ISPLATE', label: 'Date Of Payout' },
        { key: 'DATUM_TRANSFERA', label: 'Date Of Payment' },
        { key: 'STATUS', label: 'Status' },
        { key: '', label: '' }
    ];

    useEffect(() => {
        getUserTransactions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderBy, page, order]);

    useEffect(() => {
        // it needs to set page to 0 on change filter
        // if page is already 0, call getUserTransactions()
        // else set page to 0 and useEffect of the page will call getUserTransactions()
        page === 0 ? getUserTransactions() : setPage(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterParameters])

    const getUserTransactions = async () => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const userId = JSON.parse(localStorageDataAuth ? localStorageDataAuth : '').id;
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;
        setLoading(true);
        const pageNum = page + 1;
        const sortByParam = order;

        UserTransactions.getUserTransactions(userId, token, pageNum, orderBy, sortByParam, props.filterParameters).then((response) => {
            setLoading(false);
            const { data } = response;
            setJobsData(data && data.result ? data.result : []);
            setCount(data.totalCount);
        }, error => {
            setLoading(false);
        })
    }

    const redirectToView = (item: ITransactionTableUser) => {
        history.push({
            pathname: `${URLRoutes.TransactionItem}`,
            state: { data: item }
        });
    }

    const createSortHandler = (property?: string) => (event: MouseEvent<unknown>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');

        if (property) {
            setOrderBy(property);
            resetFilter();
        }
    };

    const resetFilter = () => {
        setPage(0);
        setCount(0);
    }

    const formatStatus = (transactionsStatus: string) => {
        switch (transactionsStatus) {
            case 'in-progress':
                return <Tooltip title="In Progress">
                    <div className={`${styles.orange_dot}`}></div>
                </Tooltip>

            case 'done':
                return <Tooltip title="Finished">
                    <div className={`${styles.green_dot}`}></div>
                </Tooltip>

            default:
                return <Tooltip title="In Progress">
                    <div className={`${styles.orange_dot}`}></div>
                </Tooltip>
        }
    }

    return <div className={styles.user_transaction_table_wrapper}>
        <div className={styles.user_transaction_table_header}>
            <div className={styles.recent_transaction}>Transactions</div>
            <TablePagination component="div" count={count} page={count ? page : 0} onPageChange={(event: MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage++)}
                labelRowsPerPage='' rowsPerPage={PageSizeParameters.PageSizeTen} rowsPerPageOptions={[]} />
        </div>
        <BeoExportTableContainer>
            <Table stickyHeader className={styles.transaction__table}>
                <TableHead>
                    <TableRow>
                        {headCells.map((cell: IHeaderCells, index) => (
                            <TableCell key={index}>
                                {cell.key ?
                                    <TableSortLabel
                                        active={orderBy === cell.key}
                                        direction={orderBy === cell.key ? order : 'asc'}
                                        onClick={createSortHandler(cell.key)}>
                                        {cell.label}
                                    </TableSortLabel> : cell.label
                                }
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell className={styles.full_name} >{item.RECIPEINT ? item.RECIPEINT : '/'}</TableCell>
                            <TableCell>{item.TOTAL_PAYABLE ? item.TOTAL_PAYABLE : '/'}</TableCell>
                            <TableCell>{item.UKP_ISPLATA ? item.UKP_ISPLATA : '/'}</TableCell>
                            <TableCell>{item.PAYMENT_METOD ? item.PAYMENT_METOD : '/'}</TableCell>
                            <TableCell>{item.SVRHA_TRANSFERA ? item.SVRHA_TRANSFERA : '/'}</TableCell>
                            <TableCell>{item.NACIN_ISPLATE ? item.NACIN_ISPLATE : '/'}</TableCell>
                            <TableCell>{item.DATUM_ISPLATE !== '0000-00-00' ? formatDate(item.DATUM_ISPLATE) : '/'}</TableCell>
                            <TableCell>{formatDate(item.DATUM_TRANSFERA)}</TableCell>
                            <TableCell>{formatStatus(item.TRANSACTION_STATUS)}</TableCell>
                            <TableCell>
                                <span className={`icon_svg icon_arrow`} onClick={() => redirectToView(item)}></span>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </BeoExportTableContainer>
        <Loading loading={loading} />
    </div>
}

export default UserTransactionTable;