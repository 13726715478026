import Modal from '@material-ui/core/Modal';
import React, { useEffect, useState } from 'react'
import EditProfile from '../../components/EditProfile/EditProfile';
import { LocalStorage } from '../../enums/LocalStorage';
import styles from './Profile.module.scss';
import ProfileService from '../../services/Profile';
import { formatDate } from '../../helpers/DateTimeHelper';
import { IProfilData } from '../../interface/TransferMoney';
import { ISnackbar } from '../../interface/Snackbar';
import SnackbarAlert from '../../components/SnackbarAlert/SnackbarAlert';
import moment from 'moment';

const Profile = () => {
    const [openModal, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [profileData, setProfileData] = useState<any>();
    const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false, severity: 'success', message: '' });

    useEffect(() => {
        getProfileData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProfileData = () => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const userId = JSON.parse(localStorageDataAuth ? localStorageDataAuth : '').id;
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null

        ProfileService.getUserInformation(userId, token).then(response => {
            const { data } = response;

            if (data && data[0] && data[0].DATUM_ISTEKA_ID) {
                data[0].DATUM_ISTEKA_ID = formatDate(data[0].DATUM_ISTEKA_ID);
            }
            setProfileData(data[0]);
        })
    }

    const saveNewProfilData = (parameters: IProfilData) => {
        // parameters.DATUM_ISTEKA_ID = moment(parameters.DATUM_ISTEKA_ID, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const userId = JSON.parse(localStorageDataAuth ? localStorageDataAuth : '').id;
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null

        if (typeof parameters.DATUM_ISTEKA_ID === 'object' && parameters.DATUM_ISTEKA_ID !== null) {
            parameters.DATUM_ISTEKA_ID = moment(parameters.DATUM_ISTEKA_ID).format('DD/MM/YYYY');
        }

        if (typeof parameters.DAT_RODJ === 'object' && parameters.DAT_RODJ !== null) {
            parameters.DAT_RODJ = moment(parameters.DAT_RODJ).format('DD/MM/YYYY');
        }

        // parameters.DAT_RODJ = moment(parameters.DATUM_ISTEKA_ID).format('DD/MM/YYYY');
        // parameters.DATUM_ISTEKA_ID = moment(parameters.DATUM_ISTEKA_ID).format('DD/MM/YYYY');

        const newUserData = new FormData()
        newUserData.append('IMG_ID', parameters.FILE_UPLOAD);

        if (parameters.FILE_UPLOAD) {
            ProfileService.updatePicture(newUserData, token).then(response => {
                getProfileData();
                setOpen(false);
            }).catch(error => {
                console.log(error)
            });
        }

        ProfileService.updateUserInformation(userId, parameters, token).then(response => {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'Profile updated.'
            })
            getProfileData();
            setOpen(false);
            snackbarAutoHide();
        }).catch(error => {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        });
    }

    const snackbarAutoHide = () => {
        setTimeout(() => {
            setSnackbar({ open: false });
        }, 5000);
    }

    return <div className={styles.profile_wrapper}>
        <div className={styles.menu_wrapper}></div>
        <div className={styles.header_section}>
            <div className={styles.title}>Profile page</div>
            <div className={styles.subtitle}>Below are your personal information, if for some reason you want to change it you can contact us via mail <span className={styles.subtitle_email}>info@beoexport.com.au</span> </div>
        </div>
        <div className={styles.information_wrapper}>
            <div className={styles.button_section}>
                <button className={`${styles.apply_button}`} onClick={handleOpen}>EDIT</button>
            </div>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-profile-title"
                aria-describedby="modal-profile-description">
                <div><EditProfile close={() => handleClose()} profileData={profileData} saveNewProfilData={saveNewProfilData} /></div>
            </Modal>
            <div className={styles.infomation_section}>
                <div className={styles.left_section}>
                    <div className={styles.circle_placeholder}>{profileData?.KOR_IME.charAt(0) + ' ' + profileData?.KOR_PREZIME.charAt(0)}</div>
                    <div className={styles.full_name}>{profileData?.KOR_IME + ' ' + profileData?.KOR_PREZIME}</div>
                    <div className={styles.email}>{profileData?.KOR_EMAIL}</div>
                    <div className={styles.country}>{profileData?.DRZAVA}</div>
                </div>
                <div className={styles.border_line}></div>
                <div className={styles.right_section}>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Address</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{profileData?.ADRESA}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>City</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{profileData?.GRAD}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Post code</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{profileData?.POST_BR}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>State</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{profileData?.REGIJA}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Telephone number</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{profileData?.BR_TEL}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Type of document</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{profileData?.VRSTA_DOKUMENTA}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Document number</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{profileData?.ID_DOKUMENTA}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>ID Expiry Date</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{profileData?.DATUM_ISTEKA_ID.toString().length > 10 ? formatDate(profileData?.DATUM_ISTEKA_ID) : profileData?.DATUM_ISTEKA_ID}</div>
                    </div>
                </div>
            </div>
        </div>
        <SnackbarAlert open={snackbar.open} severity={snackbar.severity} message={snackbar.message} />
    </div>
}

export default Profile;