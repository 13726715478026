import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './EditProfile.module.scss'
import CloseIcon from '@material-ui/icons/Close';
import { Box, Fab, makeStyles, MenuItem, TextField, IconButton, InputAdornment } from '@material-ui/core';
import { INewProfilData } from '../../interface/TransferMoney';
import { IProfilData } from '../../interface/TransferMoney';
import { formatDate } from '../../helpers/DateTimeHelper';
import MaskedInput from 'react-maskedinput'
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import moment from 'moment';
import { Country, DocymentType, States } from '../../enums/Enums';
import PopupFirstStep from '../Dialog/DialogFirstStep';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import DatePicker from 'react-date-picker';

const EditProfile = (props: INewProfilData) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        p: 4,
    };
    const useStyles = makeStyles((theme) => ({
        inputRoot: {
            "&$disabled": {
                backgroundColor: "gray"
            }
        },
        disabled: {}
    }));
    const classes = useStyles();
    const [newProfileData, setNewProfileData] = useState<IProfilData>(props.profileData);
    const [saveButton, setSaveButton] = useState<boolean>(false);

    const [errorInputFirstName, setErrorInputFirstName] = useState(false);
    const [errorInputLastName, setErrorInputLastName] = useState(false);
    const [errorInputEmail, setErrorInputEmail] = useState(false);
    const [errorInputTelephoneNumber, setErrorInputTelephoneNumber] = useState(false);

    const [errorInputCountry, setErrorInputCountry] = useState(false);
    const [errorInputState, setErrorInputState] = useState(false);
    const [errorInputAddress, setErrorInputAddress] = useState(false);
    const [errorInputPostCode, setErrorInputPostCode] = useState(false);

    const [errorInputDocumentType, setErrorInputDocumentType] = useState(false);
    const [errorInputDocumentID, setErrorInputDocumentID] = useState(false);
    const [errorInputDocumentDate, setErrorInputDocumentDate] = useState(false);
    const [errorInputConfirmPass, setErrorInputConfirmPass] = useState(false);
    const [errorInputPass, setErrorInputPass] = useState(false);

    const [errorMsgFirstName, setErrorMsgFirstName] = useState<string | undefined>('');
    const [errorMsgLastName, setErrorMsgLastName] = useState<string | undefined>('');
    const [errorMsgEmail, setErrorMsgEmail] = useState<string | undefined>('');
    const [errorMsgTelephoneNumber, setErrorMsgTelephoneNumber] = useState<string | undefined>('');

    const [errorMsgCountry, setErrorMsgCountry] = useState<string | undefined>('');
    const [errorMsgState, setErrorMsgState] = useState<string | undefined>('');
    const [errorMsgAddress, setErrorMsgAddress] = useState<string | undefined>('');
    const [errorMsgPostCode, setErrorMsgPostCode] = useState<string | undefined>('');

    const [errorMsgDocumentType, setErrorMsgDocumentType] = useState<string | undefined>('');
    const [errorMsgDocumentID, setErrorMsgDocumentID] = useState<string | undefined>('');
    const [errorMsgDocumentDate, setErrorMsgDocumentDate] = useState<string | undefined>('');
    const [errorMsgConfirmPass, setErrorMsgConfirmPass] = useState<string | undefined>('');

    const [confirmPass, setConfirmPass] = useState<string | undefined>('');


    const [selectedFile, setSelectedFile] = useState<any>([]);
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [dateValue, setChangeDateValue] = useState<Date | null>();
    const [dateHasValue, setDateHasValue] = useState(false);

    const [dateValueDOB, setChangeDateValueDOB] = useState<Date | null>();
    const [dateHasValueDOB, setDateHasValueDOB] = useState(false);

    const [errorMsgPassword, setErrorMsgPassword] = useState<string | undefined>('');
    const [errorInputPassword, setErrorInputPassword] = useState(false);


    const handleClick = () => {
        setShowPassword(prev => !prev);
    }

    const handleClickConfirmPass = () => {
        setShowConfirmPassword(prev => !prev);
    }


    const handleChange = (event: ChangeEvent<{ name?: string; value: string }>) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name as keyof typeof newProfileData;

        switch (name) {
            case 'KOR_IME': {
                setErrorMsgFirstName(helperValidateOnlyLetters(value))
                setErrorInputFirstName(helperValidateOnlyLetters(value) ? true : false);
                break;
            }
            case 'KOR_PREZIME': {
                setErrorMsgLastName(helperValidateOnlyLetters(value))
                setErrorInputLastName(helperValidateOnlyLetters(value) ? true : false);
                break;
            }
            case 'KOR_EMAIL': {
                setErrorMsgEmail(helperValidateEmail(value))
                setErrorInputEmail(helperValidateEmail(value) ? true : false);

                break;
            }
            case 'BR_TEL': {
                setErrorMsgTelephoneNumber(helperValidateTelephoneNumber(value))
                setErrorInputTelephoneNumber(helperValidateTelephoneNumber(value) ? true : false);
                break;
            }
            case 'GRAD': {
                setErrorMsgAddress(helperValidateAddress(value))
                setErrorInputAddress(helperValidateAddress(value) ? true : false);
                break;
            }


            case 'ADRESA': {
                // setErrorMsgAddress(helperValidateAddress(value))
                // setErrorInputAddress(helperValidateAddress(value) ? true : false);
                break;
            }

            case 'DRZAVA': {
                if (value === Country.None) {
                    setErrorInputCountry(true);
                    setErrorMsgCountry('Please select Country!');
                } else {
                    setErrorInputCountry(false);
                    setErrorMsgCountry('');
                }
                break;
            }

            case 'REGIJA': {
                if (value === States.None) {
                    setErrorInputState(true);
                    setErrorMsgState('Please select State!');
                } else {
                    setErrorInputState(false);
                    setErrorMsgState('');
                }
                break;
            }

            case 'POST_BR': {
                setErrorMsgPostCode(helperValidatePostCode(value))
                setErrorInputPostCode(helperValidatePostCode(value) ? true : false);
                break;
            }

            case 'VRSTA_DOKUMENTA': {
                if (value === DocymentType.None) {
                    setErrorInputDocumentType(true);
                    setErrorMsgDocumentType('Please select Docyment Type!');
                } else {
                    setErrorInputDocumentType(false);
                    setErrorMsgDocumentType('');
                }
                break;
            }

            case 'ID_DOKUMENTA': {
                setErrorMsgDocumentID(helperValidateNumberAndLetters(value))
                setErrorInputDocumentID(helperValidateNumberAndLetters(value) ? true : false);
                break;
            }

            case 'DATUM_ISTEKA_ID': {
                // setErrorMsgDocumentDate(helperValidateDate(value));
                // setErrorInputDocumentDate(helperValidateDate(value) ? true : false);
                // value = moment(new Date(value)).format('YYYY-MM-DD');
                // for (let index = 0; index < value.length; index++) {
                //     if (value[index] !== '_' && index === 9) {
                //         const date = compareDate(value);
                //         value = moment(date).format('YYYY-MM-DD');
                //     }
                // }
                // value = moment(value).format('YYYY-MM-DD');
                break;
            }

            // case 'LOZINKA': {
            //     if (value.length > 0) {
            //         setErrorInputPass(true);
            //     } else if (value === '' || value.length === 0) {
            //         setErrorInputPass(false);
            //         setErrorMsgConfirmPass('');
            //         setSaveButton(true);
            //     }
            //     break;
            // }

            case 'LOZINKA': {

                if (value.length > 0) {
                    setErrorInputPass(true);
                    setErrorMsgPassword(helperValidatePassword(value))
                    setErrorInputPassword(helperValidatePassword(value) ? true : false);
                    setSaveButton(false);
                } else if (value === '' || value.length === 0) {
                    setErrorInputPass(false);
                    setErrorMsgConfirmPass('');
                    setSaveButton(true);
                } else

                    break;
            }
        }

        setNewProfileData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setSaveButton(true);
    }

    const handleChangeConfirmPass = (event: ChangeEvent<{ name?: string; value: string }>) => {
        const target = event.target;
        let value = target.value;

        setConfirmPass(value);
        if (newProfileData.LOZINKA !== value) {
            setErrorInputConfirmPass(true);
            setErrorMsgConfirmPass('Passwords do not match!');
            setSaveButton(false);
        } else {
            setErrorInputConfirmPass(false);
            setErrorMsgConfirmPass('');
            setSaveButton(true);
        }

    }

    useEffect(() => {

        if (!errorMsgEmail && !errorMsgTelephoneNumber && !errorMsgFirstName && !errorMsgLastName && !errorMsgAddress && !errorMsgPostCode && !errorMsgDocumentID &&
            newProfileData && newProfileData.KOR_EMAIL.length >= 6 && newProfileData.BR_TEL.length >= 9 && newProfileData.KOR_IME.length >= 2 && newProfileData.KOR_PREZIME.length >= 2 && newProfileData.ADRESA.length >= 2 && newProfileData.POST_BR.length >= 2 && newProfileData.ID_DOKUMENTA.length >= 2
            && !errorMsgConfirmPass && !errorInputPass && !errorMsgPassword && !errorInputConfirmPass
        ) {
            setSaveButton(true);
        } else {
            setSaveButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newProfileData]);

    const helperValidatePassword = (password: string) => {
        let regPass = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        if (password.length < 8 || !regPass.test(password)) {
            return 'The password should have at least 8 Characters, combination of uppercase, lower cases, special character and numbers!'
        }
    }


    const helperValidateOnlyLetters = (input: string) => {
        if (input.replace(/^[A-Za-z]+$/, '').length > 0) return 'Only letters allowed!';
    }

    const helperValidateTelephoneNumber = (input: string) => {
        if (input.replace(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, '').length > 0) return 'Telephone number not valid!';
    }

    const helperValidateEmail = (email: string) => {
        let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.length > 0 && !regEmail.test(email)) {
            return 'Invalid Email Address';
        }
    }

    const helperValidateAddress = (input: string) => {
        if (input.replace(/^[a-zA-Z0-9\s,'-]*$/, '').length > 0) return 'Only letters and numbers allowed!';
    }

    const helperValidatePostCode = (input: string) => {
        if (input.replace(/^[0-9][0-9\- ]{0,10}[0-9]$/, '').length > 0) return 'Only numbers allowed!';
    }

    const helperValidateNumberAndLetters = (input: string) => {
        if (input.replace(/^[a-z0-9]+$/i, '').length > 0) return 'Only letters and numbers allowed!';
    }

    const saveProfil = () => {
        if (props.saveNewProfilData) {
            if (confirmPass?.length === 0) {
                delete newProfileData.LOZINKA;
            }
            props.saveNewProfilData(newProfileData);
            setSaveButton(false);
        }
    }

    const changeFileUploadHandler = (event: any) => {
        const newName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + "." + event.target.files[0].name.split('.').pop();

        let test = renameFile(event.target.files[0], newName);

        setSaveButton(true);
        setSelectedFile(test);
        setIsFilePicked(true);

        setNewProfileData(prevState => ({
            ...prevState,
            IMG_ID: newName ? newName : Math.random().toString(36).substring(2, 15)
        }));

        setNewProfileData(prevState => ({
            ...prevState,
            FILE_UPLOAD: test
        }));

    };

    const renameFile = (originalFile: File, newName: string) => {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }

    const removeUpload = () => {
        setSelectedFile([]);
        setIsFilePicked(false);
        setNewProfileData(prevState => ({
            ...prevState,
            FILE_UPLOAD: null
        }));
        setNewProfileData(prevState => ({
            ...prevState,
            IMG_ID: null
        }));
    }

    useEffect(() => {
        if (props.profileData && props.profileData.DAT_RODJ && props.profileData.DAT_RODJ !== "Invalid date" && props.profileData.DAT_RODJ !== '0000-00-00') {
            props.profileData.DAT_RODJ = formatDate(props.profileData.DAT_RODJ);
            const [day, month, year] = props.profileData.DAT_RODJ.split('/');
            const date = new Date(+year, month - 1, +day);

            props.profileData.DAT_RODJ = date;
            setChangeDateValueDOB(date);
        } else {
            setChangeDateValueDOB(null);
        }

        if (props.profileData && props.profileData.DATUM_ISTEKA_ID && props.profileData.DATUM_ISTEKA_ID !== "Invalid date" && props.profileData.DATUM_ISTEKA_ID !== '0000-00-00') {
            props.profileData.DATUM_ISTEKA_ID = formatDate(props.profileData.DATUM_ISTEKA_ID);            
            const [day, month, year] = props.profileData.DATUM_ISTEKA_ID.split('/');
            const date = new Date(+year, month - 1, +day);

            props.profileData.DATUM_ISTEKA_ID = date;
            setChangeDateValue(date);
        } else {
            setChangeDateValue(null);
        }

        // if (props.firstLogin) {
        //     if (props.profileData && props.profileData.DATUM_ISTEKA_ID) {
        //         props.profileData.DATUM_ISTEKA_ID = formatDate(props.profileData.DATUM_ISTEKA_ID);
        //     }
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.profileData]);

    const compareDate = (str: string) => {
        // str1 format should be dd/mm/yyyy. Separator can be anything e.g. / or -. It wont effect
        const dt1 = parseInt(str.substring(0, 2));
        const mon1 = parseInt(str.substring(3, 5));
        const yr1 = parseInt(str.substring(6, 10));
        const date1 = new Date(yr1, mon1 - 1, dt1);
        return date1;
    }

    const onChangeDateValueInput = (value: Date) => {
        setChangeDateValue(value);
        const newDateFormat = moment(value).format('DD/MM/YYYY');

        setDateHasValue(newDateFormat.length > 0 ? true : false);

        setNewProfileData(prevState => ({
            ...prevState,
            ['DATUM_ISTEKA_ID']: newDateFormat
        }));
        setSaveButton(true);
    }



    const onChangeDateValueInputDOB = (value: Date) => {
        setChangeDateValueDOB(value);
        const newDateFormat = moment(value).format('DD/MM/YYYY');

        setDateHasValueDOB(newDateFormat.length > 0 ? true : false);

        setNewProfileData(prevState => ({
            ...prevState,
            ['DAT_RODJ']: newDateFormat
        }));
        setSaveButton(true);
    }

    return <Box sx={style}>
        {props.profileData !== undefined ?
            <div className={styles.edit_profile_container}>
                {!props.hideCloseIcon ? <CloseIcon className={styles.close_icon} onClick={props.close} /> : null}
                <div className={styles.title_container}>
                    <div>
                        <div className={styles.title}> {props.firstLogin ? 'Please fill in the information.' : 'Edit Profile.'}</div>
                        <div className={styles.subtitle}> {props.firstLogin ? ' All fields are mandatory.' : 'Edit your personal information.'}</div>
                    </div>
                </div>
                <div className={styles.autocomplete_container}>
                    <div>
                        <div className={styles.input_label}>First Name</div>
                        <TextField autoComplete='off' name='KOR_IME' value={newProfileData?.KOR_IME} helperText={errorMsgFirstName} error={errorInputFirstName} className={styles.input_field} placeholder={'First Name'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 60 }} />
                    </div>
                    <div>
                        <div className={styles.input_label}>Last Name</div>
                        <TextField autoComplete='off' name='KOR_PREZIME' value={newProfileData?.KOR_PREZIME} helperText={errorMsgLastName} error={errorInputLastName} className={styles.input_field} placeholder={'Last Name'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 60 }} />
                    </div>
                    <div>
                        <div className={styles.input_label}>Date of birth</div>
                        <DatePicker onChange={(date: Date) => onChangeDateValueInputDOB(date)} value={dateValueDOB} format={'dd/MM/yyyy'} name="DAT_RODJ" />

                        {/* <MaskedInput mask="11/11/1111" value={newProfileData?.DAT_RODJ || ''} className={styles.input_field_masked} name="DAT_RODJ" placeholder="dd/mm/yyyy" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} /> */}
                    </div>
                </div>

                <div className={styles.autocomplete_container}>
                    <div>
                        <div className={styles.input_label}>Email</div>
                        <TextField autoComplete='off' name='KOR_EMAIL' InputProps={{ classes: { disabled: classes.disabled } }} disabled={true} value={newProfileData?.KOR_EMAIL} helperText={errorMsgEmail} error={errorInputEmail} className={styles.input_field} placeholder={'Email'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 90 }} />
                    </div>
                    {
                        !props.firstLogin ?
                            <div>
                                <div className={styles.input_label}>New Password</div>
                                {/* <TextField autoComplete='off' name='LOZINKA' className={styles.input_field} placeholder={'New Password'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 120 }} /> */}

                                <TextField autoComplete='off' name='LOZINKA' className={styles.input_field} variant="outlined" placeholder={'New Password'} type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClick}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    helperText={errorMsgPassword} error={errorInputPassword} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 120 }} />
                            </div>
                            : null
                    }
                    {
                        !props.firstLogin ?
                            <div>
                                <div className={styles.input_label}>Confirm Password</div>
                                {/* <TextField autoComplete='off' name='POTVRDA_LOZINKA' value={confirmPass} helperText={errorMsgConfirmPass} error={errorInputConfirmPass} className={styles.input_field} placeholder={'Confirm Password'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeConfirmPass(e)} inputProps={{ maxLength: 120 }} /> */}

                                <TextField autoComplete='off' name='POTVRDA_LOZINKA' value={confirmPass} helperText={errorMsgConfirmPass} error={errorInputConfirmPass} className={styles.input_field} variant="outlined" placeholder={'Confirm Password'} type={showConfirmPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickConfirmPass}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeConfirmPass(e)} inputProps={{ maxLength: 120 }} />
                            </div>
                            : null
                    }


                    <div>
                        <div className={styles.input_label}>Telephone number</div>
                        <TextField autoComplete='off' name='BR_TEL' value={newProfileData?.BR_TEL} helperText={errorMsgTelephoneNumber} error={errorInputTelephoneNumber} className={styles.input_field} placeholder={'Telephone number'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 30 }} />
                    </div>
                </div>


                <div className={styles.autocomplete_container}>
                    <div className={styles.type_section}>
                        <div className={styles.input_label}>Country</div>
                        <TextField name='DRZAVA' variant="outlined" select value={newProfileData?.DRZAVA || Country.None} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}>
                            <MenuItem className={styles.type_item} value={Country.None} aria-label="None">None</MenuItem>
                            <MenuItem className={styles.type_item} value={Country.Australia}>Australia</MenuItem>
                        </TextField>
                        <div className={styles.error_msg_date}>{errorInputCountry ? errorMsgCountry : ''}</div>
                    </div>
                    <div className={styles.type_section}>
                        <div className={styles.type_section}>
                            <div className={styles.input_label}>State</div>
                            <TextField name='REGIJA' variant="outlined" select value={newProfileData?.REGIJA || States.None} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}>
                                <MenuItem className={styles.type_item} value={States.None} aria-label="None">None</MenuItem>
                                <MenuItem className={styles.type_item} value={States.NewSouthWales}>New South Wales</MenuItem>
                                <MenuItem className={styles.type_item} value={States.SouthAustralia}>South Australia</MenuItem>
                                <MenuItem className={styles.type_item} value={States.Victoria}>Victoria</MenuItem>
                                <MenuItem className={styles.type_item} value={States.Queensland}>Queensland</MenuItem>
                                <MenuItem className={styles.type_item} value={States.Tasmania}>Tasmania</MenuItem>
                                <MenuItem className={styles.type_item} value={States.WesternAustralia}>Western Australia</MenuItem>
                            </TextField>
                            <div className={styles.error_msg_date}>{errorInputState ? errorMsgState : ''}</div>
                        </div>
                    </div>
                    <div>
                        <div className={styles.input_label}>City</div>
                        <TextField autoComplete='off' name='GRAD' value={newProfileData?.GRAD} helperText={errorMsgPostCode} error={errorInputPostCode} className={styles.input_field} placeholder={'City'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 60 }} />
                    </div>
                    <div>
                        <div className={styles.input_label}>Address</div>
                        <TextField autoComplete='off' name='ADRESA' value={newProfileData?.ADRESA} helperText={errorMsgAddress} error={errorInputAddress} className={styles.input_field} placeholder={'Address'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 120 }} />
                    </div>


                </div>

                <div className={styles.autocomplete_container}>
                    <div>
                        <div className={styles.input_label}>Post code</div>
                        <TextField autoComplete='off' name='POST_BR' value={newProfileData?.POST_BR} helperText={errorMsgPostCode} error={errorInputPostCode} className={styles.input_field} placeholder={'Post code'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 10 }} />
                    </div>
                    <div className={styles.type_section}>

                        <div className={styles.input_label}>Type of document</div>
                        <TextField name='VRSTA_DOKUMENTA' variant="outlined" select value={newProfileData?.VRSTA_DOKUMENTA || DocymentType.None} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}>
                            <MenuItem className={styles.type_item} value={DocymentType.None} aria-label="None">None</MenuItem>
                            <MenuItem className={styles.type_item} value={DocymentType.DriversLicence}>Drivers Licence</MenuItem>
                            <MenuItem className={styles.type_item} value={DocymentType.Passport}>Passport</MenuItem>
                            <MenuItem className={styles.type_item} value={DocymentType.PhotoID}>Photo ID</MenuItem>
                            <MenuItem className={styles.type_item} value={DocymentType.Other}>Other</MenuItem>
                        </TextField>
                        <div className={styles.error_msg_date}>{errorInputDocumentType ? errorMsgDocumentType : ''}</div>
                    </div>
                    <div>
                        <div className={styles.input_label}>Document number</div>
                        <TextField autoComplete='off' name='ID_DOKUMENTA' value={newProfileData?.ID_DOKUMENTA} helperText={errorMsgDocumentID} error={errorInputDocumentID} className={styles.input_field} placeholder={'Document number'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 60 }} />
                    </div>
                    <div>
                        <div className={styles.input_label}>ID Expiry Date</div>
                        {/* <MaskedInput mask="11/11/1111" value={newProfileData?.DATUM_ISTEKA_ID || ''} className={styles.input_field_masked} name="DATUM_ISTEKA_ID" placeholder="dd/mm/yyyy" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} /> */}
                        {/* <input className={styles.input_date} type="date" id="start" name="DATUM_ISTEKA_ID"
                            value={newProfileData?.DATUM_ISTEKA_ID || ''} placeholder="dd/mm/yyyy"
                            min="2018-01-01" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} /> */}

                        <DatePicker onChange={(date: Date) => onChangeDateValueInput(date)} value={dateValue} format={'dd/MM/yyyy'} minDate={new Date()} name="DATUM_ISTEKA_ID" />

                        <div className={styles.error_msg_date}>{errorInputDocumentDate ? errorMsgDocumentDate : ''}</div>
                    </div>


                </div>

                <div className={styles.autocomplete_container}>
                    <div>
                        <div className={styles.input_label}>Upload Copy of ID</div>
                        <label htmlFor="upload-photo">
                            <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" onChange={(e) => changeFileUploadHandler(e)} accept=".png, .jpg, .jpeg, .pdf" />
                            <Fab color="secondary" size="small" component="span" aria-label="add" variant="extended">
                                <AddIcon /> Upload photo
                            </Fab>
                        </label>
                    </div>
                    {
                        isFilePicked ?
                            <div className={styles.file_upload}>
                                <div className={styles.input_label}>File Name</div>
                                <div className={styles.file_upload_name}>{selectedFile?.name} <span onClick={removeUpload}> <HighlightOffIcon /> </span> </div>
                            </div>
                            : null
                    }
                </div>

                <div className={styles.buttons_container}>
                    <button className={`${styles.save_button} ${!saveButton ? styles.disabled : ''}`} onClick={saveProfil}>SAVE</button>
                </div>
            </div>
            : null

        }

    </Box>

}

export default EditProfile;