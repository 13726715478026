import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { formatDate } from '../../../../helpers/DateTimeHelper';
import styles from './TransactionViewAdmin.module.scss';
import { URL } from "../../../../enums/Url";
import { Box, Modal } from '@material-ui/core';

const TransactionViewAdmin = () => {
    const history = useHistory();
    const [transactionsData, setTransactionsData] = useState<any>();
    const [openModal, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 0,
        boxShadow: 24,
        p: 4,
    };
    const [showImgUrl, setShowImageUrl] = useState<any>();

    useEffect(() => {
        const recData: any = history.location.state;
        if (recData) {
            setTransactionsData(recData.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location]);

    const truncate = (string: string) => {
        if (string.length > 15) {
            return string.substring(0, 15) + '...';
        } else {
            return string;
        }
    };

    const openImg = (img: string) => {
        handleOpen();
        setShowImageUrl(img);
    }


    return <div className={styles.transaction_view_wrapper}>
        <div className={styles.menu_wrapper}></div>
        <div className={styles.go_back_button} onClick={history.goBack}> <span className={`icon_svg icon_arrow ${styles.span_icon}`}></span> Go Back </div>
        <div className={styles.header_section}>
            <div className={styles.title}>Transactions: {transactionsData && transactionsData.ID ? transactionsData.ID : '/'}</div>
            <div className={styles.subtitle}>Below are expanded transactions details.</div>
        </div>
        <div className={styles.information_wrapper}>
            <div className={styles.infomation_section}>
                <div className={styles.left_section}>
                    <div className={styles.circle_placeholder}>{transactionsData && transactionsData.RECIPEINT ?
                        transactionsData.RECIPEINT.split(' ').slice(0, -1).join(' ').charAt(0) + "" +
                        transactionsData.RECIPEINT.split(' ').slice(-1).join(' ').charAt(0) : '/'}</div>
                    <div className={styles.full_name}> {transactionsData && transactionsData.RECIPEINT ? transactionsData.RECIPEINT : '/'}</div>
                    <div className={styles.email}>{transactionsData && transactionsData.RECIPEINT_EMAIL ? transactionsData.RECIPEINT_EMAIL : '/'}</div>
                    <div className={styles.country}>{transactionsData && transactionsData.RECIPEINT_COUNTRY ? transactionsData.RECIPEINT_COUNTRY : '/'}</div>
                    <div className={styles.documents_pictures_wrapper}>
                        {transactionsData && transactionsData.SLIKA_PRIZNANICA ?
                            <div>
                                <div className={styles.circle_placeholder_doc} onClick={() => openImg(`${URL.BaseURL}/uploads/${transactionsData && transactionsData.SLIKA_PRIZNANICA ? transactionsData.SLIKA_PRIZNANICA : ''}`)}>
                                    <img className={styles.image_wrapper} src={`${URL.BaseURL}/uploads/${transactionsData && transactionsData.SLIKA_PRIZNANICA ? transactionsData.SLIKA_PRIZNANICA : ''}`} alt="" />
                                </div>
                                <div className={styles.title}>Picture of receipt</div>
                            </div>
                            : null
                        }

                        {transactionsData && transactionsData.SLIKA_INSTRUKCIJA_BANKE ?
                            <div>
                                <div className={styles.circle_placeholder_doc} onClick={() => openImg(`${URL.BaseURL}/uploads/${transactionsData && transactionsData.SLIKA_INSTRUKCIJA_BANKE ? transactionsData.SLIKA_INSTRUKCIJA_BANKE : ''}`)}>
                                    <img className={styles.image_wrapper} src={`${URL.BaseURL}/uploads/${transactionsData && transactionsData.SLIKA_INSTRUKCIJA_BANKE ? transactionsData.SLIKA_INSTRUKCIJA_BANKE : ''}`} alt="" />
                                </div>
                                <div className={styles.title}>Picture of bank instruction</div>
                            </div>
                            : null
                        }
                    </div>

                </div>

                <div className={styles.right_section}>
                    <div className={styles.section_name}>Recipient</div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Recipient ID</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.RECIPEINT_ID ? transactionsData.RECIPEINT_ID : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} `}>
                        <div className={styles.title}>Recipient</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.RECIPEINT ? transactionsData.RECIPEINT : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Address</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.RECIPEINT_ADDRESS ? truncate(transactionsData.RECIPEINT_ADDRESS) : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} `}>
                        <div className={styles.title}>City</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.RECIPEINT_CITY ? transactionsData.RECIPEINT_CITY : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Post code</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.RECIPEINT_POST_CODE ? transactionsData.RECIPEINT_POST_CODE : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Region</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.RECIPEINT_REGION ? transactionsData.RECIPEINT_REGION : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Country</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.RECIPEINT_COUNTRY ? transactionsData.RECIPEINT_COUNTRY : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} `}>
                        <div className={styles.title}>Tel. num.</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.RECIPEINT_MOBILE_NUMBER ? transactionsData.RECIPEINT_MOBILE_NUMBER : '/'}</div>
                    </div>

                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Email</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value_email}>{transactionsData && transactionsData.RECIPEINT_EMAIL ? transactionsData.RECIPEINT_EMAIL : '/'}</div>
                    </div>

                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Proffesion</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.RECIPEINT_PROFESSION ? transactionsData.RECIPEINT_PROFESSION : '/'}</div>
                    </div>

                    <div className={`${styles.text_row}  ${styles.with_background}`}>
                        <div className={styles.title}>Date of Birth</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.RECIPEINT_DATE_OF_BIRTH ? formatDate(transactionsData.RECIPEINT_DATE_OF_BIRTH) : '/'}</div>
                    </div>

                </div>

                <div className={styles.right_section}>
                    <div className={styles.section_name}>Sender</div>
                    <div className={`${styles.text_row}  ${styles.with_background}`}>
                        <div className={styles.title}>ID</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.SENDER_ID ? transactionsData.SENDER_ID : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} `}>
                        <div className={styles.title}>Sender</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.SENDER ? transactionsData.SENDER : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Address</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.SENDER_ADDRESS ? transactionsData.SENDER_ADDRESS : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>City</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.SENDER_CITY ? transactionsData.SENDER_CITY : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Post code</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.SENDER_POSTCODE ? transactionsData.SENDER_POSTCODE : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Region</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.SENDER_REGION ? transactionsData.SENDER_REGION : '/'}</div>
                    </div>

                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Country</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.SENDER_COUNTRY ? transactionsData.SENDER_COUNTRY : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Tel. num.</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.SENDET_PHONE ? transactionsData.SENDET_PHONE : '/'}</div>
                    </div>

                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Email</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value_email}>{transactionsData && transactionsData.SENDE_EMAIL ? transactionsData.SENDE_EMAIL : '/'}</div>
                    </div>

                    <div className={`${styles.text_row} `}>
                        <div className={styles.title}>Proffesion</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.SENDER_PROFESSION ? transactionsData.SENDER_PROFESSION : '/'}</div>
                    </div>

                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Date of Birth</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.SENDER_DATE_OF_BIRTH ? formatDate(transactionsData.SENDER_DATE_OF_BIRTH) : '/'}</div>
                    </div>
                </div>
                {/* <div className={styles.border_line}></div> */}

                <div className={styles.right_section}>
                    <div className={styles.section_name}>Transaction</div>
                    {/* <div className={`${styles.section_title_second} ${styles.section_title}`}>Transaction Information</div> */}
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Payout - EUR</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.TOTAL_PAYMENT ? transactionsData.TOTAL_PAYMENT : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>AUD/EUR</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.EXCHANGE_RATE_CURRENCY ? transactionsData.EXCHANGE_RATE_CURRENCY : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Paying Amount</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.PAYING_AMOUNT ? transactionsData.PAYING_AMOUNT : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Fee</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.FREE ? transactionsData.FREE : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Other costs</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && String(transactionsData.OTHER_COSTS) ? transactionsData.OTHER_COSTS : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Total payable</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.TOTAL_PAYABLE ? transactionsData.TOTAL_PAYABLE : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Payout amount</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.PAYOUT_AMOUNT ? transactionsData.PAYOUT_AMOUNT : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Transfer date</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.DATUM_TRANSFERA ? formatDate(transactionsData.DATUM_TRANSFERA) : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Date of payment</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.DATUM_ISPLATE ? formatDate(transactionsData.DATUM_ISPLATE) : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Payment Currency</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.VALUTA_ISPLATE ? transactionsData.VALUTA_ISPLATE : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Payment method</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.PAYMENT_METOD ? transactionsData.PAYMENT_METOD : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Purpose of transfer</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.SVRHA_TRANSFERA ? transactionsData.SVRHA_TRANSFERA : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Payout method</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{transactionsData && transactionsData.NACIN_ISPLATE ? transactionsData.NACIN_ISPLATE : '/'}</div>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-profile-title"
            aria-describedby="modal-profile-description">

            <Box sx={style}>
                <div className={styles.modal_wrapper}>
                    <img src={`${showImgUrl}`} alt="" />
                </div>
            </Box>
        </Modal>
    </div>
}

export default TransactionViewAdmin;