// import { Button, Popover } from '@material-ui/core';
// import React, { useState } from 'react'
// import { useLocation } from 'react-router-dom';
// import ExchangeRateFilter from '../../../components/Filters/ExchangeRateFilter/ExchangeRateFilter';
import { Modal } from '@material-ui/core';
import { useRef, useState } from 'react';
import SnackbarAlert from '../../../components/SnackbarAlert/SnackbarAlert';
import AddExchangeRate from '../../../components/Tables/ExchangeRateTable/AddExchangeRate/AddExchangeRate';
import ExchangeRateTable from '../../../components/Tables/ExchangeRateTable/ExchangeRateTable';
import { ISnackbar } from '../../../interface/Snackbar';
import styles from './ExchangeRate.module.scss';
import AdminService from '../../../services/Admin';
import { LocalStorage } from '../../../enums/LocalStorage';
import EditExchangeRate from '../../../components/Tables/ExchangeRateTable/EditExchangeRate/EditExchangeRate';
import moment from 'moment';

const ExchangeRate = () => {
    // const location = useLocation();
    // const queryString = require('query-string');
    // const tableUrlParams = ['page', 'order_by'];
    // const [filterDialog, setFilterDialog] = useState<HTMLButtonElement>();
    // const [filterParams, setFilterParams] = useState<any>(queryString.parse(location.search) ? Object.fromEntries(Object.entries(queryString.parse(location.search)).filter(([k, _]) => !tableUrlParams.includes(k))) : {});

    // const isEmptyObject = (object = {}) => {
    //     return Object.keys(object).length === 0;
    // }

    // const applyFilters = (parameters: any | undefined) => {
    //     setFilterParams(parameters);
    //     setFilterDialog(undefined);
    // }

    const [openModal, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [exchangeRateData, setExchangeRateData] = useState<any>();
    const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false, severity: 'success', message: '' });
    const childRef = useRef<any>();

    const [openModalEdit, setOpenEdit] = useState(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    const saveExchangeRate = (option: any) => {
        setExchangeRateData(null);
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null
        const parameters = {
            code: 1,
            currency: 'AUD/EUR SEND',
            rate: option.currency,
            mark: 'EUR',
            date: option.currencyDate
        }

        AdminService.addNewExchangeRate(token, parameters).then(response => {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'New exchange rate added.'
            })
            setOpen(false);
            childRef?.current.getRefresData();
            snackbarAutoHide();
        }).catch(error => {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        });
    }

    const editExchangeRate = (option: any) => {
        // console.log('option', option);
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null
        const parameters = {
            KURS: option.KURS,
            date: moment(option.DATUM).format('YYYY-MM-DD')
        }

        AdminService.updateExchangeRate(option.ID, token, parameters).then(response => {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'Exchange rate edited.'
            })
            setOpenEdit(false);
            childRef?.current.getRefresData();
            snackbarAutoHide();
            handleCloseEdit();
        }).catch(error => {
            handleCloseEdit();
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        });
    }

    const editOption = (option: any) => {
        setExchangeRateData(option);
        handleOpenEdit();
    }

    const snackbarAutoHide = () => {
        setTimeout(() => {
            setSnackbar({ open: false });
        }, 5000);
    }


    return <div className={styles.admin_exchange_rate_wrapper}>
        <div className={styles.header_section}>
            <div className={styles.title}>Exchange rate</div>
            <div className={styles.box_wrapper}>
                <div className={styles.title}>Add Exchange rate</div>
                <div className={styles.subtitle}>Add new exchange rate to Beo-Export.</div>
                <div className={styles.button_section}>
                    <div><button onClick={handleOpen}>ADD NEW</button></div>
                </div>
            </div>
        </div>
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-profile-title"
            aria-describedby="modal-profile-description">
            <div><AddExchangeRate close={() => handleClose()} saveExchangeRateData={saveExchangeRate} /></div>
        </Modal>

        <Modal
            open={openModalEdit}
            onClose={handleCloseEdit}
            aria-labelledby="modal-profile-title"
            aria-describedby="modal-profile-description">
            <div><EditExchangeRate close={() => handleCloseEdit()} ExchangeRateData={exchangeRateData} editExchangeRate={editExchangeRate} /></div>
        </Modal>
        {/* <div className={styles.admin_exchange_rate_filter}>
            <Button className={`${styles.filter_circle} ${!isEmptyObject(filterParams) ? styles.active_filter : ''}`} onClick={(e) => setFilterDialog(e.currentTarget)}><span className="icon icon_filter"></span></Button>
            <Popover id='admin_exchange_rate_filter' open={Boolean(filterDialog)} anchorEl={filterDialog} onClose={() => setFilterDialog(undefined)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <ExchangeRateFilter appliedFilters={filterParams} applyFilters={applyFilters} close={() => setFilterDialog(undefined)} datasetFilter={true} />
            </Popover>
        </div> */}
        <ExchangeRateTable editOption={editOption} ref={childRef} />
        <SnackbarAlert open={snackbar.open} severity={snackbar.severity} message={snackbar.message} />
    </div>
}

export default ExchangeRate;